import React from 'react'
import PropTypes from 'prop-types'

import { optionListItemStyle, hiddenAnchorStyle, flexMiddle } from '../styles'

import RadioInput from '../RadioInput/RadioInput'

const OptionListItemRadio = ({ id, isSelected, onClick, label, children, hidden }) => (
    <div key={id} style={optionListItemStyle} hidden={hidden}>
        <div className='d-flex' onClick={(e) => { e.preventDefault(); onClick(id); }}>
            <RadioInput selected={isSelected} style={flexMiddle} onClick={() => onClick(id)} />
            <button className='btn btn-link' style={hiddenAnchorStyle}>{label}</button>
        </div>
        {children}
    </div>);


OptionListItemRadio.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired
}

export default OptionListItemRadio