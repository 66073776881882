import React from 'react';
import ArticleSearchResults from '../Articles/ArticleSearchResults';
import ProductSearchResults from '../Products/ProductSearchResults';
import PropTypes from 'prop-types';
import {
    ARTICLES,
    PRODUCTS
} from '../../constants/index.js';
import WithLoading from '../../components/WithLoading';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card, CardHeader, CardBody } from 'reactstrap';

const CombinedSearchResults = ({ articles,
    articlePaginationViewModel,
    onArticleCurrentItemsPerPageChange,
    onArticleCurrentPageIndexChange,
    onResultClick,
    attributes,
    onAttributeChange,
    onClearAttributes,
    onProductCurrentItemsPerPageChange,
    onProductCurrentSortByChange,
    onProductCurrentPageIndexChange,
    productPaginationViewModel,
    products,
    view,
    onChangeView,
    isLoading,
    onShowMobileFacetsChange,
    expandedFacets,
    onToggleFacetExpanded,
    showMoreFacets,
    onToggleShowMoreFacet
}) => {
    const showArticles = view === ARTICLES;
    const showProducts = !showArticles;

    return (
        <div className="row combined-search-wrapper">
            <div className="col-md-12">
                <Card>
                    <CardHeader>
                        <div className="nav nav-tabs card-header-tabs">
                            <NavItem>
                                <NavLink
                                    className={showProducts ? "active" : ""}
                                    onClick={()=>onChangeView(PRODUCTS)}
                                    href="#"
                                >
                                    Products
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={showArticles ? "active" : ""}
                                    onClick={()=>onChangeView(ARTICLES)}
                                    href="#"
                                >
                                    Articles
                                </NavLink>
                            </NavItem>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <TabContent activeTab={view}>
                            <TabPane tabId={PRODUCTS}>
                                <ProductSearchResultsWithLoading
                                        attributes={attributes}
                                        onAttributeChange={onAttributeChange}
                                        onClearAttributes={onClearAttributes}
                                        onCurrentItemsPerPageChange={onProductCurrentItemsPerPageChange}
                                        onCurrentSortByChange={onProductCurrentSortByChange}
                                        onCurrentPageIndexChange={onProductCurrentPageIndexChange}
                                        paginationViewModel={productPaginationViewModel}
                                        products={products}
                                        onResultClick={onResultClick}
                                        isLoading={isLoading}
                                        onShowMobileFacetsChange={onShowMobileFacetsChange}
                                        expandedFacets={expandedFacets}
                                        onToggleFacetExpanded={onToggleFacetExpanded}
                                        showMoreFacets={showMoreFacets}
                                        onToggleShowMoreFacet={onToggleShowMoreFacet}
                                    />
                            </TabPane>
                            <TabPane tabId={ARTICLES}>
                                <ArticleSearchResultsWithLoading
                                        articles={articles}
                                        paginationViewModel={articlePaginationViewModel}
                                        onCurrentItemsPerPageChange={onArticleCurrentItemsPerPageChange}
                                        onCurrentPageIndexChange={onArticleCurrentPageIndexChange}
                                        onResultClick={onResultClick}
                                        isLoading={isLoading}
                                    />
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

const ArticleSearchResultsWithLoading = WithLoading(ArticleSearchResults);
const ProductSearchResultsWithLoading = WithLoading(ProductSearchResults);

CombinedSearchResults.propType = {
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            Title: PropTypes.string,
            Link: PropTypes.string,
            Description: PropTypes.string
        })
    ),
    articlePaginationViewModel: PropTypes.shape({
        CurrentPageIndex: PropTypes.number,
        CurrentItemsPerPage: PropTypes.number,
        ResultsTotal: PropTypes.number,
        CurrentItemsOnPage: PropTypes.number
    }),
    onArticleCurrentItemsPerPageChange: PropTypes.func,
    onArticleCurrentPageIndexChange: PropTypes.func,
    onResultClick: PropTypes.func,
    attributes: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        DisplayName: PropTypes.string,
        Values: PropTypes.arrayOf(PropTypes.shape({
            Name: PropTypes.string,
            Checked: PropTypes.bool,
            Count: PropTypes.number
        }))
    })),
    onAttributeChange: PropTypes.func,
    onClearAttributes: PropTypes.func,
    onProductCurrentItemsPerPageChange: PropTypes.func, 
    onProductCurrentSortByChange: PropTypes.func, 
    onProductCurrentPageIndexChange: PropTypes.func, 
    productPaginationViewModel: PropTypes.shape({
        ResultsTotal: PropTypes.number,
        CurrentItemsPerPage: PropTypes.number,
        CurrentPageIndex: PropTypes.number,
        CurrentSortBy: PropTypes.string,
        CurrentItemsOnPage: PropTypes.number
    }),
    products: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string,
        Image: PropTypes.shape({
            Url: PropTypes.string,
            Alt: PropTypes.string
        }),
        Description: PropTypes.string
    })),
    view: PropTypes.string,
    onChangeView: PropTypes.func,
    onShowMobileFacetsChange: PropTypes.func.isRequired,
    expandedFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleFacetExpanded: PropTypes.func,
    showMoreFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleShowMoreFacet: PropTypes.func,
};

export default CombinedSearchResults;