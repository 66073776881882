import React from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'

import { ERROR_USER_ALERT } from './userAlertTypes'

import { Fade } from '../../componentAnimations'

const hasNewMessage = (oldMessages, newMessages) => {
  return !newMessages.every((cur) => oldMessages.some(msg => msg.id === cur.id))
}

// BBS DDD from https://stackoverflow.com/questions/487073/check-if-element-is-visible-after-scrolling?noredirect=1&lq=1
// answer by bravedick
const isScrolledIntoView = (el) => {
  if (typeof window === 'undefined' || window.innerWidth === 'undefined')
    return false;
  var elemTop = el.getBoundingClientRect().top;
  var elemBottom = el.getBoundingClientRect().bottom;

  // Only completely visible elements return true:
  var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
  // Partially visible elements return true:
  //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

class MessagesList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { visibleMessages: 0 }
  }

  componentWillReceiveProps(nextProps) {
    if (hasNewMessage(this.props.messages, nextProps.messages)) {
      this.setState({ scrollToLast: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.scrollToLast) {
      const highestMessageId = this.props.messages.reduce((max, cur) => cur.id > max ? cur.id : max, 0)
      const messageWithHighestId = findDOMNode(this.refs[highestMessageId.toString(10)])
      isScrolledIntoView(messageWithHighestId) || messageWithHighestId.scrollIntoView({behavior: "smooth"})
      this.setState({scrollToLast: false})
    }
  }

  render() {
    const children = this.props.messages.map(message => {
      if (message.dismissed)
        return "";
      return (
        <Fade inProp={true} key={message.id}>
          <div
            className={`alert alert-dismissable alert-${message.type === ERROR_USER_ALERT ? "danger" : "info"}`}
            role="alert"
            style={{ marginTop: 10 }}
            ref={message.id.toString(10)}
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={(e) => e.preventDefault() || this.props.onDismiss(message.Id)}>
              <span aria-hidden="true">&times;</span>
            </button>
            {message.text.split('\n').map((line, i) =>
              (<p key={i}>{line}</p>))}
          </div>
        </Fade>
      )
    }).filter(x => x);

    return (
      <div className="messages">
        {children}
      </div>
    )
  }
}

MessagesList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }))
}

export default MessagesList