import React from 'react'
import { CheckoutSection, formatMoney } from '../../components'
import { translateResourceString } from '../../../util/translationUtility';
import styles from './checkout.module.css';

const spinnerSpan = <span className="fa fa-spinner fa-spin" />;

const CheckoutSummary = (
  {
    summary: {
      ShippingMethod,
      ShippingTotal,
      SubTotal,
      Total,
      Currency,
      TaxTotal,
      DiscountTotal
    },
    isRefreshing
  }) => {
  const formatCurrency = formatMoney(Currency);
  return (
    <CheckoutSection label='Summary' hidden={false}>
        {isRefreshing
              ? spinnerSpan
              : (
                  <table className={styles.summaryTable} style={{width: "100%"}}>
            <tbody>
            <tr className="subtotal">
              <td className="summary-label">
                {translateResourceString("/Checkout/Summary/SubtotalLabel")}
              </td>
              <td className="summary-amount">
                {formatCurrency(SubTotal)}
              </td>
            </tr>
            {TaxTotal != null &&
            <tr className="tax">
              <td className="summary-label">
                {translateResourceString("/Checkout/Summary/TaxLabel")}
              </td>
              <td className="summary-amount">
                {formatCurrency(TaxTotal)}
              </td>
            </tr>
            }
            {ShippingTotal != null &&
            <tr className="shipping">
              <td className="summary-label">
                  {translateResourceString("/Checkout/Summary/ShippingLabel")}
                <div className={styles.shippingMethod}>
                  <em>{ShippingMethod}</em>
                </div>
              </td>
              <td className="summary-amount">
                {ShippingTotal === 0 ? "Free" : formatCurrency(ShippingTotal || 0)}
              </td>
            </tr>
            }
            {DiscountTotal != null &&
                <tr className="discount">
                  <td className="summary-label">
                    Discount
              </td>
                  <td className="summary-amount">
                    {formatCurrency(DiscountTotal)}
                  </td>
                </tr>
            }
            <tr className={styles.total}>
              <td className="total-label">
                  {translateResourceString("/Checkout/Summary/TotalLabel")}
              </td>
              <td className="total-amount">
                {formatCurrency(Total)}
              </td>
            </tr>
            </tbody>
          </table>)}
    </CheckoutSection>
  );
}

export default CheckoutSummary