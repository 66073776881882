// eslint-disable-next-line
import React from 'react'
import { connect } from 'react-redux'

import { notifyError, dismissAllMessages } from '../UserMessaging'
import { translateResourceString } from '../../../util/translationUtility';

import { completeCheckoutAsync } from './placeOrderActionCreators'
import { mapStateToPlaceOrderDetails } from './mapStateToPlaceOrderDetails'
import CheckoutComplete from './CheckoutComplete'


const mapCheckoutCompleteStateToProps = (state, ownProps) => {
    var placeOrderDetails = mapStateToPlaceOrderDetails(state);
    placeOrderDetails.constants = state.constants; // Hack on the constants object so we can see what constant values are when we validate
    return {
        buttonText: state.ui.completeCheckoutButtonText,
        placeOrderDetails: placeOrderDetails,
        isPlaceOrderPending: state.ui.isPlaceOrderPending,
        ...ownProps
    }
}

const addressIsMissingIdOrRequiredData = function (address) {
    return (
        !address
        || !address.Id // True if address.Id is falsy
        || !(          // True if any fields are falsy
            !!address.Name &&
            !!address.AddressLine1 &&
            !!address.City &&
            !!address.State &&
            !!address.CountryCode &&
            !!address.PostalCode))
};

const SelectedBillingAddressCanBeSubmitted = (address, billingEqualsShipping) => {
    return (
        // If truthy, then return the message at the end, otherwise return falsy
        (!billingEqualsShipping && addressIsMissingIdOrRequiredData(address))
        && translateResourceString("/Checkout/PlaceOrder/Errors/InvalidBillingAddress")
    )
}

const paymentIsInvalid = (constants, { Id, POValue, BraintreePaymentNonce }) => {
    if (!Id) {
        return translateResourceString("/Checkout/PlaceOrder/Errors/NoPaymentSelect");
    } else if (Id === constants.braintreeSettings.braintreePaymentId && !BraintreePaymentNonce) {
        return translateResourceString("/Checkout/PlaceOrder/Errors/PaymentInvalid");
    }
    return false;
}

// BBS DDD 2017-12-01 This archaic validation could probably be 
// replaced. see mapTopLevelPPropsToInitialStore.js for suggestion of valdatejs.org or such
const orderDetailsCanBeSubmitted = (orderDetails) => {
    const keys = Object.keys(orderDetails);
    return keys
        .map(key => { // look through the keys of order details
            const value = orderDetails[key];
            // for each key, validate that we have an
            // object we can submit

            const billingEqualsShippingKey = 'BillingEqualsShipping';
            switch (key) {

                // If our validation fails and we get a true
                // value for the condition, then return a message
                // for this key
                case 'SelectedShippingAddress':
                    return (!value || !value.Id) && translateResourceString("/Checkout/PlaceOrder/Errors/ShippingAddressNotSelected");

                case 'SelectedBillingAddress':
                    return SelectedBillingAddressCanBeSubmitted(value, orderDetails[billingEqualsShippingKey]);

                case 'SelectedShippingOption':
                    return (!value || !value.Id) && translateResourceString("/Checkout/PlaceOrder/Errors/ShippingOptionNotSelected");

                // We want an object with an Id and either a POValue or a BraintreePaymentNonce
                case 'SelectedPayment':
                    return paymentIsInvalid(orderDetails.constants, value);

                case billingEqualsShippingKey:
                    return (typeof BillingEqualsShipping === 'boolean');

                // We just stick constants on our place order details object so we can see them
                case 'constants':
                    return false;

                case 'Coupons':
                  return false;
                // Unknown properties in orderDetails => validation failure
                default:
                    return `${translateResourceString("/Checkout/PlaceOrder/Errors/UnknownOrderDetail")}: '${key}'`;
            }
        })
        .filter(x => !!x);
    // Only return something truthy, which should only be non-empty strings
    // whose value is a message giving a reason why validation failed

}

const mapCheckoutCompleteDispatchToProps = (dispatch) => {
    return {
        onCompleteCheckout: (placeOrderDetails) => {
            dispatch(dismissAllMessages());
            const errors = orderDetailsCanBeSubmitted(placeOrderDetails);
            if (errors.length === 0) {
                delete placeOrderDetails.constants; // Remove the property we stuck on to share state above in mapCheckoutCompleteStateToProps
                dispatch(completeCheckoutAsync(placeOrderDetails));
            } else {
                const intro = translateResourceString("/Checkout/PlaceOrder/Errors/Intro") + ": ";
                const message = errors.reduce((acc, cur) => acc + '\n' + cur, intro);
                dispatch(notifyError(message));
            }
        }
    }
}

const CheckoutCompleteContainer = connect(mapCheckoutCompleteStateToProps, mapCheckoutCompleteDispatchToProps)(CheckoutComplete)

export default CheckoutCompleteContainer