import React from 'react';
import PropTypes from 'prop-types';
import fetch from 'cross-fetch';

import QuickOrderEntry from "./Features/QuickOrderEntry";

import { createGuid } from '../util/guidUtility';
import { TranslationUtility } from '../util/translationUtility';

import { MessagesList, ERROR_USER_ALERT, INFO_USER_ALERT } from '../SharedComponents/UserMessaging/';

let messageId = 0;

const createUserMessage = ({ text, type }) => ({ id: messageId++, text, type });

class QuickOrderApp extends React.Component {
    constructor(props) {
        super(props);

        this.translationUtility = new TranslationUtility(props.LocalizationItems);

        this.state = {
            entries: [
                this.createEntry()
            ],
            isAddingToCart: false,
            userMessages: []
        };
    }

    componentDidMount() {
        require('./QuickOrderApp.css');
    }

    componentDidUpdate(prevProps, prevState) {
        const entries = this.state.entries;
        for (let i = 0; i < entries.length; i++) {
            if (entries[i].hasFocus && entries[i].query !== prevState.entries[i].query) {
                this.handleQueryChanged(i);
            }
        }
    }

    createEntry() {
        return {
            key: createGuid(),
            query: "",
            searchedQuery: "",
            selectedSku: "",
            quantity: -1,
            itemName: "",
            itemMinQty: 1,
            itemMaxQty: 999999,
            searchCompleted: false,
            isValid: false,
            isEmpty: true,
            hasFocus: false,
            autocompleteItems: []
        };
    }

    onFocusChanged(index, hasFocus) {
        this.setState(prevState => {
            const entries = prevState.entries;
            entries[index].hasFocus = hasFocus;
            return { entries };
        });
        let entry = this.state.entries[index];
        if (!hasFocus && entry.autocompleteItems.length === 1 && entry.autocompleteItems[0].Sku === entry.query) {
            this.onEntrySkuSet(index, entry.query);
        }
    }

    deleteRow = (index) => {
        const rows = [...this.state.entries];

        if (rows.length === (index + 1)) {
            return;
        }

        rows.splice(index, 1);
        this.setState({ entries: rows });
    }

    onEntryQueryChange = (index, query) => {
        if (this.state.entries[index].ignoreChange) return;

        let entries = JSON.parse(JSON.stringify(this.state.entries));
        entries[index].isEmpty = query === "";
        entries[index].query = query;
        entries[index].searchCompleted = false;
        if (!!entries[index].selectedSku && entries[index].selectedSku !== query) {
            entries[index].selectedSku = "";
            entries[index].isValid = false;
        }

        this.setState({ entries });
    }

    handleQueryChanged = (index) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.getAutocompleteItems(index);
        }, 500);
    }

    onEntrySkuSet = (index, sku) => {
        var item = this.state.entries[index].autocompleteItems.find((e) => { return e.Sku === sku; });
        var updatedEntries = JSON.parse(JSON.stringify(this.state.entries));

        if (!!item) {
            updatedEntries[index].query = item.Sku;
            updatedEntries[index].selectedSku = item.Sku;
            updatedEntries[index].itemName = item.VariationName;
            updatedEntries[index].quantity = item.MinQty || 0;
            updatedEntries[index].itemMinQty = item.MinQty;
            updatedEntries[index].itemMaxQty = item.MaxQty;
            updatedEntries[index].autocompleteItems = [];
            updatedEntries[index].isValid = true;

            if (!!updatedEntries[updatedEntries.length - 1].selectedSku) {
                updatedEntries.push(this.createEntry());
            }
        } else {
            updatedEntries[index].selectedSku = "";
            updatedEntries[index].itemName = "";
            updatedEntries[index].quantity = 0;
            updatedEntries[index].isValid = false;
        }

        this.setState({ entries: updatedEntries })
    }

    addRow = () => {
        this.setState((prevState) => {
            return { entries: [...prevState.entries, this.createEntry()] }
        });
    }

    onQuantityChange = (index, qty) => {
        this.setState((prevState) => {
            var entriesCopy = [...prevState.entries];
            var currentEntry = entriesCopy[index];

            var quantity = Number(qty) || -1;

            currentEntry.quantity = quantity;
            return { entries: entriesCopy };
        });
    }

    onQuantityBlur = (index, qty) => {
        this.setState((prevState) => {
            var entriesCopy = [...prevState.entries];
            var currentEntry = entriesCopy[index];

            var quantity = Number(qty) || -1;

            if (quantity < currentEntry.itemMinQty) {
                quantity = currentEntry.itemMinQty;
            } else if (quantity > currentEntry.itemMaxQty) {
                quantity = currentEntry.itemMaxQty;
            }

            currentEntry.quantity = quantity;
            return { entries: entriesCopy };
        });
    }

    onAddItemsToCart = () => {
        this.setState({ isAddingToCart: true });

        var addToCartPromises = [];
        var entriesCopy = [...this.state.entries];
        var entriesAdded = 0;

        for (let i = 0; i < this.state.entries.length - 1; i++) {// There should always be one extra entry
            addToCartPromises.push(this.addItemToCart(this.state.entries[i].selectedSku, this.state.entries[i].quantity));
            entriesCopy.splice(i - entriesAdded, 1);
            entriesAdded++;
        }

        //this.state.entries.forEach((entry, i) => {
        //    addToCartPromises.push(this.addItemToCart(entry.selectedSku, entry.quantity));
        //    entriesCopy.splice(i - entriesAdded, 1);
        //    entriesAdded++;
        //});

        var messages = [];
        Promise
            .all(addToCartPromises)
            .then((values) => {
                values.forEach(value => {
                    messages.push(value);
                });
            })
            .finally(() => {
                this.setState({
                    entries: entriesCopy,
                    isAddingToCart: false,
                    userMessages: messages
                });
                this.updateMiniCart();
            });
    }

    addItemToCart = (code, quantity) => {
        const submitMethod = 'POST';
        return new Promise((resolve) => {
            fetch(
                this.props.AddToCartEndpoint,
                {
                    method: submitMethod,
                    headers: {
                        'content-type': 'application/json',
                        'X-Requested-With': 'fetch'
                    },
                    credentials: 'same-origin',
                    body: JSON.stringify({ code: code, quantity: quantity })
                }
            ).then((response) => {
                if (response.ok) {
                    resolve(createUserMessage({
                        text: this.translationUtility.translateResourceString("/QuickOrder/ItemWithSkuAddedFormatted", code),
                        type: INFO_USER_ALERT
                    }));
                } else {
                    if (typeof console !== 'undefined' && console.log) {
                        console.error(`Error sending ${submitMethod} to ${this.props.AddToCartEndpoint}: (${response.statusCode}) ${response.statusText}`);
                    }
                    var errorMessage = response.statusText;
                    resolve(createUserMessage({
                        text: this.translationUtility.translateResourceString("/QuickOrder/ErrorFormatted", code, errorMessage, errorMessage && errorMessage[errorMessage.length] === '.' || '.')``,
                        type: ERROR_USER_ALERT
                    }));
                }
            });
        });
    }

    // Updates mini cart - could be moved to another file if we need to call it elsewhere
    updateMiniCart = () => {
        if (window && window.MiniCart && window.MiniCart.refresh) {
            window.MiniCart.refresh();
        }
    }

    onDismissMessage = (id) => {
        this.setState({
            userMessages: (this.state.userMessages
                ? this.state.userMessages.filter((message) => message.Id !== id)
                : [])
        });
    }

    renderLoginMessage = () => {
        const {
            LoginPageLink,
            RegistrationPageLink
        } = this.props;


        return (
            <span className="entry-label unauthorized-label">
                <a href={LoginPageLink} className="quick-order__unauthorized-link">{this.translationUtility.translateResourceString('/QuickOrder/LoginLinkText')}</a> {this.translationUtility.translateResourceString('/QuickOrder/TextAfterLoginLink')} <a href={RegistrationPageLink} className="quick-order__unauthorized-link">{this.translationUtility.translateResourceString('/QuickOrder/RegisterLinkText')}</a> {this.translationUtility.translateResourceString('/QuickOrder/TextAfterRegisterLink')}
            </span>
        );

    }

    getAutocompleteItems = (index) => {
        fetch(this.props.AutocompleteEndpoint + '?query=' + this.state.entries[index].query)
            .then(response => response.json())
            .then(data => {
                let entries = JSON.parse(JSON.stringify(this.state.entries));
                entries[index].searchCompleted = true;
                entries[index].searchedQuery = entries[index].query
                entries[index].autocompleteItems = data;
                this.setState({ entries });
            });
    }

    render() {
        const {
            entries
        } = this.state;
        const isSubmitting = this.state.isAddingToCart;
        const addToCartLabel = isSubmitting ? this.translationUtility.translateResourceString("/QuickOrder/AddingItemsButton") : this.translationUtility.translateResourceString("/QuickOrder/AddToCartButton");
        const entriesLength = entries.length;
        const invalidEntries = !entries || entries.length === 0 || entries.filter(ent => !ent.isValid && !ent.isEmpty).length > 0 || entries.filter(ent => ent.isValid).length === 0;
        if (!this.props.UserIsAuthorized) {
            return (
                <div className="quick-order">
                    <div className="quick-order-unauthorized">
                        {this.renderLoginMessage()}
                    </div>
                </div>
            );
        }

        return (
            <div className="quick-order p-3 input-container">
                {entries.map((entry, index) =>
                    <QuickOrderEntry
                        deleteRow={() => this.deleteRow(index)}
                        onQueryChange={(value) => this.onEntryQueryChange(index, value)}
                        onSkuSet={(value) => this.onEntrySkuSet(index, value)}
                        onQuantityChange={(value) => this.onQuantityChange(index, value)}
                        onQuantityBlur={(value) => this.onQuantityBlur(index, value)}
                        onFocusChanged={(hasFocus) => this.onFocusChanged(index, hasFocus)}
                        //onRef={ref => (entry.getVariationCode = ref.getVariationCode)}
                        hideCloseButton={index === (entriesLength - 1)}
                        translationUtility={this.translationUtility}
                        autocompleteItems={entry.autocompleteItems}
                        {...entry}
                    />
                )}


                <div className="row justify-content-end">
                    <div className="col-12">
                        <button
                            className="btn btn-primary"
                            disabled={isSubmitting || invalidEntries}
                            onClick={this.onAddItemsToCart}
                        >
                            {addToCartLabel}
                        </button>
                        <a className="btn btn-link" href={this.props.BulkUploadUrl} title={this.translationUtility.translateResourceString('/QuickOrder/BulkUploadButton')}>
                            {this.translationUtility.translateResourceString('/QuickOrder/BulkUploadButton')}
                        </a>
                    </div>
                </div>

                <div className="row justify-content-start">
                    <div className="col-12">
                        <MessagesList
                            messages={this.state.userMessages}
                            onDismiss={this.onDismissMessage}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

QuickOrderApp.propTypes = {
    ApiEndPoint: PropTypes.string,
    AddToCartEndpoint: PropTypes.string,
    UserIsAuthorized: PropTypes.bool,
    LoginPageLink: PropTypes.string,
    RegistrationPageLink: PropTypes.string,
    LocalizationItems: PropTypes.array,
    BulkUploadUrl: PropTypes.string
};

export default QuickOrderApp;