import React from 'react';
import Pager from '../../components/Pager';
import SortByDropdown from '../../components/SortByDropdown';
import PerPageDropdown from '../../components/PerPageDropdown';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

class PaginationContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        require('./PaginationContainer.css');
    }

    render() {
        const {
            paginationViewModel,
            onCurrentItemsPerPageChange,
            onCurrentSortByChange,
            onCurrentPageIndexChange,
            onShowMobileFacetsChange
        } = this.props;
        const firstProductNumber = paginationViewModel.CurrentPageIndex * paginationViewModel.CurrentItemsPerPage + 1;
        const lastProductNumber = firstProductNumber + paginationViewModel.CurrentItemsOnPage - 1;
        return (
            <div className="row category-list-pagination-wrapper">
                <div className="col-12">
                    <div className="pull-right form-inline pagination-dropdown-wrapper">
                        <label className="total-results hidden-sm hidden-xs">
                            Results {firstProductNumber} - {lastProductNumber} of {paginationViewModel.ResultsTotal}
                        </label>
                        <SortByDropdown {...paginationViewModel} onCurrentSortByChange={onCurrentSortByChange} />
                    </div>
                </div>
            </div>
        );
    }
};

PaginationContainer.propTypes = {
    paginationViewModel: PropTypes.shape({
        ResultsTotal: PropTypes.number,
        CurrentItemsPerPage: PropTypes.number,
        CurrentPageIndex: PropTypes.number,
        CurrentSortBy: PropTypes.string,
        CurrentItemsOnPage: PropTypes.number
    }),
    onCurrentItemsPerPageChange: PropTypes.func,
    onCurrentSortByChange: PropTypes.func,
    onCurrentPageIndexChange: PropTypes.func,
    onShowMobileFacetsChange: PropTypes.func.isRequired
};

export default PaginationContainer;