import React from 'react'
import { TabList } from 'react-tabs'
import PropTypes from 'prop-types'
import ModalTab from './ModalTab'
import NewListInput from '../../Components/NewListInput'
import { translateResourceString } from '../../../util/translationUtility'

const ModalTabList = ({
    Lists = [],
    UpdateLists,
    ActiveTabIndex,
    SelectActiveTab = null,
    CreateListEndpoint
}) => {
    return (
        <TabList className="col-12 col-lg-8 p-0">
            {
                Lists.map((list, i) =>
                    <ModalTab key={i} {...list} IsActive={ActiveTabIndex === i ? true : false} />
                )
            }
            <div className="p-3 mt-5 modal-create-list">
                <h4>{translateResourceString("/Wishlists/ViewAndAddModal/CreateListLabel")}</h4>
                <NewListInput 
                    Lists={Lists} 
                    UpdateLists={UpdateLists} 
                    SelectActiveTab={SelectActiveTab}
                    CreateListEndpoint={CreateListEndpoint} />
            </div>
        </TabList>
    );
};

ModalTabList.propType = {
    Lists: PropTypes.array.isRequired,
    UpdateLists: PropTypes.func.isRequired,
    ActiveTabIndex: PropTypes.number.isRequired,
    SelectActiveTab: PropTypes.func,
    CreateListEndpoint: PropTypes.string.isRequired
};

export default ModalTabList;