import React from 'react'
import { TabList } from 'react-tabs'
import PropTypes from 'prop-types'
import WishlistsTab from './WishlistsTab'
import NewListModalLink from './Features/NewList/NewListModalLink';

const WishlistsTabList = ({
    Lists = [],
    ToggleNewListModal,
    ToggleEditListModal,
    ActiveTabIndex
}) => {
    return (
        <div className="col-4 d-none d-md-block list-sidebar">
            <TabList className="nav flex-column">
                {
                    Lists.map((list, i) =>
                        <WishlistsTab
                            key={`${i}-${list.Name}-Tab`}
                            {...list}
                            IsActive={ActiveTabIndex === i ? true : false}                     
                            ToggleEditListModal={ToggleEditListModal} />
                    )
                }
            </TabList>

            <NewListModalLink ToggleNewListModal={ToggleNewListModal} />
        </div>
    );
};

WishlistsTabList.propType = {
    Lists: PropTypes.array.isRequired,
    ToggleNewListModal: PropTypes.func.isRequired,
    ToggleEditListModal: PropTypes.func.isRequired,
    ActiveTabIndex: PropTypes.number.isRequired
};

export default WishlistsTabList;