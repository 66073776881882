import React from 'react';
import PropTypes from 'prop-types';

import { NumberInputSelect } from '../../components';
import { translateResourceString } from '../../../util/translationUtility';

const maxDropdownQuantity = 10;

class CartItem extends React.Component {
    constructor(props) {
        super(props);
        this.onQuantityChange = this.onQuantityChange.bind(this);
    }


    onQuantityChange(qty) {
        if (qty > 0) {
            this.props.onQuantityChange(this.props.item.Id, Number(qty));
        } else {
            this.props.onDelete(this.props.item.Id, this.props.item.Quantity);
        }
    }

    render() {
        const item = this.props.item;

        return (
            <div className="media cart-item">
                <img alt={item.Name} className="mr-1" src={item.ImageUrl} style={{ "maxWidth": "64px" }} />

                <div className="media-body d-flex justify-content-between w-100">
                    <div style={{ marginRight: '1rem' }}>
                        <h4 className="headline headline-sm">{item.Name}</h4>
                        <div className="checkout-cart-item-sku" style={{ marginRight: 10 }}>
                            {translateResourceString("/Checkout/Cart/ItemSkuLabel")}: {item.SKU}
                        </div>

                    </div>
                    <span>
                        {translateResourceString("/Checkout/Cart/ItemPriceLabel")}: {this.props.item.Price}
                    </span>
                    <NumberInputSelect
                        className="input-container"
                        maxDropdownQuantity={maxDropdownQuantity}
                        componentClassName={"checkout-cart-item-quanity"}
                        quantity={this.props.item.Quantity}
                        onChange={this.onQuantityChange}
                        onDelete={(e) => {
                            typeof e !== 'undefined' && typeof e.preventDefault !== 'undefined' && e.preventDefault();
                            this.props.onDelete(item.Id, this.props.item.Quantity);
                        }}
                    />

                </div>
            </div>
        );
    }
}

CartItem.propTypes = {
    item: PropTypes.shape({
        Name: PropTypes.string.isRequired,
        ImageUrl: PropTypes.string.isRequired,
        SKU: PropTypes.string.isRequired,
        Quantity: PropTypes.number.isRequired
    }).isRequired,
    onDelete: PropTypes.func,
    onQuantityChange: PropTypes.func.isRequired
}

export default CartItem;