import React from 'react';
import PropTypes from 'prop-types';
import WithClickTracking from '../../components/WithClickTracking';
import Article from './Article';
import MoreButton from '../../components/MoreButton';

class ArticleSearchResultsContainer extends React.Component {
    render() {
        const { articles,
            paginationViewModel,
            onCurrentItemsPerPageChange,
            //onCurrentPageIndexChange,
            onResultClick } = this.props;
        const before = paginationViewModel.CurrentItemsPerPage * paginationViewModel.CurrentPageIndex;
        const first = before + 1;
        const last = before + paginationViewModel.CurrentItemsOnPage;
        const perMoreAmount = 12;
        const nextRequest = Math.min(paginationViewModel.CurrentItemsOnPage + perMoreAmount, paginationViewModel.ResultsTotal);
        const showMore = paginationViewModel.ResultsTotal > paginationViewModel.CurrentItemsOnPage;
    
        return (
            <div>
                <div className="mb-3">
                    Displaying Results {first} - {last}
                </div>
                <div className="article-search-article-item-wrapper">
                    {articles.map((article, i) => {
                        return (
                            <div key={i} className="row mb-3">
                                <div className="col-md-12">
                                    <ArticleWithClickTracking {...article} onResultClick={onResultClick} />
                                </div>
                            </div>
                        );
                    })
                    }
                </div>
                {showMore &&
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <MoreButton
                                onClick={() => onCurrentItemsPerPageChange(nextRequest)}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const ArticleWithClickTracking = WithClickTracking(Article);

ArticleSearchResultsContainer.propType = {
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            Title: PropTypes.string,
            Link: PropTypes.string,
            Description: PropTypes.string
        })
    ),
    paginationViewModel: PropTypes.shape({
        CurrentPageIndex: PropTypes.number,
        CurrentItemsPerPage: PropTypes.number,
        ResultsTotal: PropTypes.number,
        CurrentItemsOnPage: PropTypes.number
    }),
    onPerPageChange: PropTypes.func,
    onPageChange: PropTypes.func
};

export default ArticleSearchResultsContainer;