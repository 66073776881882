import React from 'react';
import Dropdown from './Dropdown';
import DropdownOption from './DropdownOption';
import PropTypes from 'prop-types';

const PRODUCTS_PER_PAGE_OPTIONS = [
    new DropdownOption(12,12),
    new DropdownOption(24,24),
    new DropdownOption(48,48),
    new DropdownOption(96,96)
];

const PerPageDropdown = ({CurrentItemsPerPage, onCurrentItemsPerPageChange}) => {
    
    const found = PRODUCTS_PER_PAGE_OPTIONS.find(option => {
        return option.value === CurrentItemsPerPage;
    });

    const selectedPerPage = found ? found : PRODUCTS_PER_PAGE_OPTIONS[0];
    
    return (
        <Dropdown options={PRODUCTS_PER_PAGE_OPTIONS} selected={selectedPerPage} onDropdownChange={onCurrentItemsPerPageChange} />
    );
};

PerPageDropdown.propTypes = {
    CurrentItemsPerPage: PropTypes.number,
    onCurrentItemsPerPageChange: PropTypes.func
};

export default PerPageDropdown;