import {
  fetchJson,
  COMPLETE_CHECKOUT,
  PLACE_ORDER_ASYNC
} from '../../GlobalStateContainer'

import { notifyCompleteCheckoutError } from '../UserMessaging'
import { translateResourceString } from '../../../util/translationUtility';

/* COMPLETE CHECKOUT */

export function completeCheckout() {
  return {type: COMPLETE_CHECKOUT}
}


/* ASYNC ACTION CREATORS */
const onPlaceOrderSuccess = (responseObject, { placeOrderDetails }, getState) => {
    if (responseObject.Success) {
        // No return, because we're navigating away from the app
        if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
            window.location.href = getState().constants.navigation.orderConfirmationUrl +"?orderNumber=" + responseObject.order.OrderNumber;
        }
        return null;
    } else {
        return notifyCompleteCheckoutError(translateResourceString("/Checkout/PlaceOrder/Errors/CouldNotCompleteCheckout") + " " + responseObject.Message,
          JSON.stringify(responseObject));
    }
}

const completeCheckoutAsyncSpec = {
    endpoint: (state) => state.constants.navigation.placeOrderEndpoint,
    init: { method: 'POST', credentials: 'same-origin' },
    notify: ( placeOrderDetails ) => ({ type: PLACE_ORDER_ASYNC, placeOrderDetails }),
    extract: (response) => response.json(),
    onSuccess: onPlaceOrderSuccess,
    onError: (diagMsg) => notifyCompleteCheckoutError(translateResourceString("/Checkout/PlaceOrder/Errors/ErrorPlacingOrder"), diagMsg)
}

export const completeCheckoutAsync = fetchJson(completeCheckoutAsyncSpec);

