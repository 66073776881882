import React from 'react';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';

const MoreButton = ({onClick}) => 
<Button
    block
    onClick={onClick}
>
    Load More <i className="fa fa-angle-down" />
</Button>;

MoreButton.propType = {
    onClick: PropTypes.func.isRequired
};

export default MoreButton;