import React from 'react'
import PropTypes from 'prop-types'

import { CheckoutSection } from '../../components'
import { notifyError } from '../UserMessaging'
import { Fade } from '../../componentAnimations'

import isEqual from 'lodash/isEqual'
import { translateResourceString } from '../../../util/translationUtility';


const placeOrderDetailsChanged = (prevProps, newProps) => !isEqual(prevProps.Coupons, newProps.Coupons) || !isEqual(prevProps.CartItems, newProps.CartItems) || !isEqual(prevProps.SelectedShippingOptionId, newProps.SelectedShippingOptionId);


class CheckoutCoupon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: false,
            newCouponCode: ''
        };
    }

    onClickChange(e) {
        e.preventDefault();
        this.setState({ isCollapsed: !this.state.isCollapsed });
    }

    onKeyPress(e) {
        if (e.key === "Enter") {
            this.addCoupon();
        }
    }

    addCoupon() {
        if (this.state.newCouponCode && this.state.newCouponCode != '') {
            this.props.onAddCoupon(this.state.newCouponCode);
            this.setState({ newCouponCode: '' });
        } else {
            alert(translateResourceString("/Checkout/Coupons/NoPromotionCodeEntered"));
        }
    }

    componentDidUpdate(prevProps) {
        if (placeOrderDetailsChanged(prevProps, this.props)) {
            this.props.updateCoupons();
        }
    }

    render() {
        const usedCoupons = this.props.Coupons ? this.props.Coupons : [];
        const appliedCoupons = usedCoupons.map(c => {
            var key = "appliedCoupons" + c.Code;
            return (
                <div className="row my-2" key={key}>
                    <div className="col-sm-3">{c.Code}</div>
                    <div className="col-sm-3">{c.Description}</div>
                    <div className="col-sm-3">
                        <a className="link" href="" onClick={(e) => { e.preventDefault(); this.props.onRemoveCoupon(c.Code) }}>
                            {translateResourceString("/Checkout/Coupons/Remove")}
                        </a>
                    </div>
                </div>
            );
        });

        return (
          <CheckoutSection label={translateResourceString("/Checkout/Coupons/SectionLabel")} hidden={false}>
                <div>
                    {this.props.Code}
                    {
                        (this.state.isCollapsed)
                  ? (<a href="" onClick={(e) => this.onClickChange(e)}><span style={{ marginLeft: 20 }}>{translateResourceString("/Checkout/Coupons/Change")}</span></a>)
                            : (<span></span>)
                    }
                </div>
                <Fade inProp={!this.state.isCollapsed}>
                    <div className="coupon-body form-gray mb-3 p-3">
                <label className="mb-3">{translateResourceString("/Checkout/Coupons/AddCouponCode")}</label>
                        <div className="d-sm-flex">
                            <input
                                id="newCouponCode"
                                key="newCouponCode"
                                type="text"
                                className="coupon-input form-control"
                                value={this.state.newCouponCode}
                                onChange={(e) => this.setState({ newCouponCode: e.target.value })}
                                onKeyPress={(e) => { this.onKeyPress(e) }}
                            />
                            <button
                                type="button"
                                className="btn btn-xs btn-neogen-burnt-orange"
                                onClick={() => { this.addCoupon() }}
                            >Ok</button>
                        </div>
                        {!!appliedCoupons.length && (
                            <div className="mb-3 collapse-xs" id="appliedCoupons">
                                <h5 className="coupon-body__applied-list-header">{translateResourceString("/Checkout/Coupons/CouponsApplied")}:</h5>
                                {appliedCoupons}
                            </div>
                        )}
                        
                    </div>
                </Fade>
            </CheckoutSection>
        );
    }
}

CheckoutCoupon.propTypes = {
    Coupons: PropTypes.array.isRequired,
    CartItems: PropTypes.array,
    SelectedShippingOptionId: PropTypes.string
}

export default CheckoutCoupon
