import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { translateResourceString } from "../../../util/translationUtility";

const EditListModal = ({
    Lists,
    ListName,
    UpdateLists,
    EditListModalIsOpen,
    ToggleEditListModal,
    CreateListEndpoint,
    RenameListEndpoint,
    DeleteListEndpoint,
    AddItemEndpoint
}) => {
    const newListName = useRef(null)
    const [copyListNumber, increaseCopyListNumber] = useState(1)

    const copyName = " - Copy"

    const updateAndClose = (list = null) => {
        if (list)
            UpdateLists(list)

        ToggleEditListModal({
            EditListModalIsOpen: false
        })
    }

    const deleteList = (listName) => {
        if (window.confirm('Are you sure you wish to delete this list?')) {
            let newList = Lists.filter(x => x.Name !== listName)
            updateAndClose(newList)
        }
    }

    const saveList = (newListName) => {
        if (newListName.length !== 0) {
            let index = Lists.findIndex(x => x.Name === ListName)
            let newList = JSON.parse(JSON.stringify([...Lists.slice(0, index), { Name: newListName, Items: Lists[index].Items }, ...Lists.slice(index + 1, Lists.length)]))
            updateAndClose(newList)
        }
    }

    const Save = (endpoint, oldListName, newListName) => {
        axios.post(endpoint, {
            oldListName: oldListName,
            newListName: newListName
        })
            .then(function (response) {
                switch (response.data) {
                    case 'OK':
                        saveList(newListName)
                        break
                    case 'Conflict':
                        alert('There is the list with the same name')
                        break
                    case 'BadRequest':
                        alert('Please check list name')
                        break
                    default:
                        console.error('Unhandled Response.')
                        break
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const Delete = (endpoint, listName) => {
        axios.post(endpoint, {
            listName: listName
        })
            .then(function (response) {
                switch (response.data) {
                    case 'OK':
                        deleteList(listName)
                        break
                    case 'BadRequest':
                        alert('Invalid parameters')
                        break
                    case 'InternalServerError':
                        alert('Something went wrong')
                        break
                    default:
                        console.error('Unhandled Response.')
                        break
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const Copy = (endpoint, listName) => {

        let name = listName + copyName
        if (Lists.some(list => list.Name === name)) {
            name += ' ' + copyListNumber
            increaseCopyListNumber(copyListNumber + 1)
        }
        let newItems = Lists.filter(list => list.Name === listName)[0].Items

        axios.post(endpoint, {
            listName: name
        })
            .then(function (response) {
                switch (response.data) {
                    case 'OK':
                        let skus = {}
                        newItems.forEach(product => skus[product.SKU] = product.Quantity)

                        axios.post(AddItemEndpoint, {
                            listName: name,
                            skus: skus
                        })
                            .then(function (response) {
                                switch (response.data) {
                                    case 'OK':
                                        let newList = [{ Name: name, Items: newItems }, ...Lists]
                                        updateAndClose(newList)
                                        break
                                    case 'InternalServerError':
                                        alert('Something went wrong')
                                        break
                                    case 'BadRequest':
                                        alert('Please check list name')
                                        break
                                    default:
                                        console.error('Unhandled Response.')
                                        break
                                }
                            })
                            .catch(function (error) {
                                console.log(error)
                            });
                        break
                    case 'Conflict':
                        alert('There is the list with the same name')
                        break
                    case 'BadRequest':
                        alert('Please check list name')
                        break
                    default:
                        console.error('Unhandled Response.')
                        break
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    return (
        <Modal isOpen={EditListModalIsOpen} toggle={() => updateAndClose()} centered={true} contentClassName="edit-modal" role="document">
            <ModalHeader toggle={() => updateAndClose()} wrapTag="div" tag="span" className="headline headline-sm">
                {translateResourceString("/Wishlists/EditModal/Header")}
            </ModalHeader>
            <ModalBody className="input-container">
                <label>{translateResourceString("/Wishlists/EditModal/ListNameLabel")}</label>
                <input type="text" name="Name" tabIndex="0" defaultValue={ListName} ref={newListName} />
            </ModalBody>
            <ModalFooter className="col-flex">
                <div>
                    <Button color="alt" size="xs" className="removeFromCart mr-2" onClick={() => Delete(DeleteListEndpoint, newListName.current.value.trim())}>
                        <span className="sr-only">{translateResourceString("/Wishlists/EditModal/DeleteButton")}</span>
                        <span className="fa fa-trash"></span>
                    </Button>
                    <Button color="alt" size="xs" onClick={() => Copy(CreateListEndpoint, ListName)}>{translateResourceString("/Wishlists/EditModal/CopyButton")}</Button>
                </div>
                <div>
                    <Button color="alt" size="xs" onClick={() => updateAndClose()}>{translateResourceString("/Wishlists/EditModal/CancelButton")}</Button>
                    <Button color="primary" size="xs" className="ml-2" onClick={() => Save(RenameListEndpoint, ListName, newListName.current.value.trim())}>{translateResourceString("/Wishlists/EditModal/SaveButton")}</Button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

EditListModal.propType = {
    Lists: PropTypes.arrayOf(
        PropTypes.shape({
            Name: PropTypes.string
        })
    ).isRequired,
    ListName: PropTypes.string.isRequired,
    UpdateLists: PropTypes.func.isRequired,
    EditListModalIsOpen: PropTypes.bool.isRequired,
    ToggleEditListModal: PropTypes.func.isRequired,
    CreateListEndpoint: PropTypes.string.isRequired,
    RenameListEndpoint: PropTypes.string.isRequired,
    DeleteListEndpoint: PropTypes.string.isRequired,
    AddItemEndpoint: PropTypes.string.isRequired
};

export default EditListModal;