import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

const PrintModalLink = ({
    TogglePrintModal
}) => {

    return (
        <Button color="alt" size="xs" title="Print" onClick={() => TogglePrintModal(true)}>
            <i className="fa fa-print"></i>
        </Button>
    );
};

PrintModalLink.propType = {
    TogglePrintModal: PropTypes.func.isRequired
};

export default PrintModalLink;