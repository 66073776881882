import React from 'react'
import PropTypes from 'prop-types'

let id = 0;

class TextFormEntry extends React.Component {
  constructor(props) {
    super(props);
    const inputIsRequired = (typeof props.isRequired === 'function') ? props.isRequired(props.name) : props.isRequired;
    this.state = {
      isOpen: !!props.value || inputIsRequired, 
      id: id++
    };
  }

  render() {
    const p = this.props;

    // what type of input are we and how are we identified?
    const isNumberInput = p.type === 'number';
    const inputId = `${this.constructor.name}-${id}-${p.name}`;

    const inputIsRequired = (typeof p.isRequired === 'function')
      ? p.isRequired(p.name)
      : p.isRequired;
    const inputIsInvalid = (typeof p.isInvalid === 'function')
      ? p.isInvalid(p.name, p.value)
      : p.isInvalid;

    // Compute some reasonable defaults
    const autocomplete = p.autocomplete || p.name;
    const placeholder = p.placeholder || p.label;
    const value = p.value || (isNumberInput ? 0 : "");
    
    if (this.state.isOpen) {
      let input;
      if (isNumberInput) {
        input = inputIsRequired
          ? (<input
            type={p.type}
            name={p.name}
            id={inputId}
            value={value}
            onInput={p.onChange}
            className="form-control"
            placeholder={placeholder}
            autoComplete={autocomplete}
            pattern={p.pattern}
            required />)
          : (<input
            type={p.type}
            name={p.name}
            id={inputId}
            value={value}
            onInput={p.onChange}
            className="form-control"
            placeholder={placeholder}
            autoComplete={autocomplete}
            pattern={p.pattern} />);
      } else {
        input = inputIsRequired
          ? (<input
            type={p.type}
            name={p.name}
            id={inputId}
            value={value}
            onChange={p.onChange}
            className="form-control"
            placeholder={placeholder}
            autoComplete={autocomplete}
            pattern={p.pattern}
            required />)
          : (<input
            type={p.type}
            name={p.name}
            id={inputId}
            value={value}
            onChange={p.onChange}
            className="form-control"
            placeholder={placeholder}
            autoComplete={autocomplete}
            pattern={p.pattern} />);
      }

      return (
        <div
              className={`input-container ${inputIsInvalid ? "error" : ""}`}
        >
          <label
            htmlFor={inputId}
            className="control-label"
          >
            {`${inputIsRequired ? "* " : ""}${p.label}:`}
          </label>
          <div className="">
            {input}
          </div>
        </div>);
    } else {
      return (
        <div className="form-group">
          <div></div>
          <div>
            <button 
              onClick={() => this.setState({ isOpen: true })} 
              className="btn btn-link p-0">
              <span className='fas fa-plus'></span> add {p.label}
            </button>
          </div>
        </div>
      )
    }
  }
}

TextFormEntry.propTypes = {
 name: PropTypes.string.isRequired,
 label: PropTypes.string.isRequired,
 onChange: PropTypes.func.isRequired,
 type: PropTypes.oneOf(['url', 'tel', 'email', 'search', 'number', 'range', 'datetime-local', 'date', 'time', 'week', 'month', 'color', 'text']),
 autocomplete: PropTypes.string,
 pattern: PropTypes.string,
 placeholder: PropTypes.string
}

TextFormEntry.defaultProps = {
  type: 'text',
  isInvalid: false,
  isRequired: false,
  pattern: '.*'
}

export default TextFormEntry;