import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

const ListsModalLink = ({
    ClassName = "",
    Color = "",
    Size = "",
    LinkText,
    ToggleListsModal
}) => {

    return (
        <Button className={ClassName} color={Color} size={Size} onClick={() => ToggleListsModal(true)}>{LinkText}</Button>
    );
};

ListsModalLink.propType = {
    ClassName: PropTypes.string,
    Color: PropTypes.string,
    Size: PropTypes.string,
    LinkText: PropTypes.string.isRequired,
    ToggleListsModal: PropTypes.func.isRequired
};

export default ListsModalLink;