import {
  SELECT_SHIPPING_OPTION,
  SELECT_SHIPPING_OPTION_ASYNC,
  NOTIFY_REFRESHING_SHIPPING_OPTIONS, 
  NEW_SHIPPING_OPTIONS,
  fetchJson 
} from '../../GlobalStateContainer'
import { notifyError } from "../UserMessaging";
import { translateResourceString } from '../../../util/translationUtility';
/* SHIPPING METHOD */

const onSelectShippingSuccess = ({ id,error }) => {
  if(error) notifyError('An error occurred selecting a shipping method', 'There was a problem obtaining shipment information.');
  return { type: SELECT_SHIPPING_OPTION, id: id }
}

export function notifySelectingShippingOption() {
  return { type: SELECT_SHIPPING_OPTION_ASYNC }
}
const selectShippingMethodAsyncSpec = {
  endpoint: (state) => state.constants.navigation.setShippingMethodEndpoint,
  init: { method: 'POST', credentials: 'same-origin' },
  notify: notifySelectingShippingOption,
  extract: (response) => response.json(),
  onSuccess: onSelectShippingSuccess,
  onError: (diagMsg) => notifyError('An error occurred refreshing the shipping methods', diagMsg)
}
export const selectShippingMethod = fetchJson(selectShippingMethodAsyncSpec);

export function notifyRefreshingShippingOptions() {
    return { type: NOTIFY_REFRESHING_SHIPPING_OPTIONS }
}


const isAllUniqueValues = (arr) => [...new Set(arr)].length === arr.length

const shippingOptionsAreInvalid = (shippingOptions) => {
    if (!isAllUniqueValues(shippingOptions.map(so => so.Id))) {
        console.error();
        return 'not all Ids unique for ShippingOptions';
    }
    return null;
}

const onRefreshShippingOptionsSuccess = ({ shippingOptions }) => {
    const invalidMessage = shippingOptionsAreInvalid(shippingOptions);
    if (invalidMessage)
        return notifyError(translateResourceString("/Checkout/ShippingMethod/Errors/ErrorRetrieving"), invalidMessage)

    return { type: NEW_SHIPPING_OPTIONS, shippingOptions }
}

const refreshShippingOptionsAsyncSpec = {
    endpoint: (state) => state.constants.navigation.refreshShippingOptionsEndpoint,
    init: { method: 'POST', credentials: 'same-origin' },
    notify: notifyRefreshingShippingOptions,
    extract: (response) => response.json(),
    onSuccess: onRefreshShippingOptionsSuccess,
    onError: (diagMsg) => notifyError(translateResourceString("/Checkout/ShippingMethod/Errors/ErrorRefreshing"), diagMsg)
}

export const refreshShippingOptionsAsync = fetchJson(refreshShippingOptionsAsyncSpec)