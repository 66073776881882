import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { translateResourceString } from '../util/translationUtility'

const WishlistProduct = ({
    Id,
    Name,
    ImageUrl,
    SKU,
    Link,
    Price,
    ListPrice,
    Quantity,
    ListIndex,
    RemoveProduct,
    ToggleCopyModal,
    ProductCommand,
    ListId,
    PricesAreHidden,
    UpdateQty,
    AddToCart,
    TotalPrice,
    isUpdating
}) => {

    const qty = useRef(Quantity)

    const prepareCopyData = (e, removeAfter = false) => {
        e.preventDefault()
        ProductCommand({
            ListId: ListId,
            ProductId: Id,
            RemoveAfter: removeAfter
        })
        ToggleCopyModal(true)
    }

    const onQuantityChange = () => {
        if (parseInt(qty.current.value) === 0) {
            RemoveProduct(SKU, ListIndex)
            qty.current.value = 1
        } else {
            UpdateQty(SKU, ListId, qty.current.value)
        }
    }

    return (
            <div className="list-item">
                <div className="col-auto item-image">
                    <a href={Link} title={Name}>
                        <img alt={`${Name} - ${SKU}`} src={ImageUrl} />
                    </a>
                </div>

                <div className="col item-info">
                    <div className="item-name">
                        <a href={Link} title={Name}>
                            <h4 className="headline headline-sm">{Name}</h4>
                        </a>
                    </div>
                    <div className="item-meta input-container">
                        <span className="sku">{SKU}</span>
                        <div className="quantity-wrapper js-variation-quantity">
                            <span className="d-none d-print-block">{translateResourceString("/Wishlists/Items/QuantityLabel")}:</span>
                            <input className="qty-option" id="Qty" name="Qty"
                                type="number" min="0" defaultValue={Quantity} ref={qty}
                                onChange={() => { onQuantityChange(); }}
                        />
                        <Button size="xs" color="icon" className="removeFromCart" onClick={() => RemoveProduct(SKU, ListIndex)} title={translateResourceString("/Wishlists/Items/DeleteItemButton")}>
                                <span className="sr-only">{translateResourceString("/Wishlists/Items/DeleteItemButton")}</span>
                                <span className="fa fa-trash"/>
                            </Button>
                        </div>
                    </div>
                </div>
        
                <div className={"col-auto " + (PricesAreHidden ? "price-hide" : "price")}>
                    <div className="price-regular">                    
                        {ListPrice && 
                        <div>
                            <span className="price-regular mr-1">
                            <strike>
                                    {translateResourceString("/Wishlists/ViewAndAddListsButton")}: {ListPrice}
                            </strike>
                            </span>
                        </div>}
                        <span className="price-customer">
                            {translateResourceString("/Wishlists/Items/UnitPriceLabel")}: {Price}
                        </span>
                        <div className="price-regular"><b>{translateResourceString("/Wishlists/Items/TotalPriceLabel")}: {TotalPrice}</b></div>
                        <Button color="secondary" size="sm" className="btn-block"
                            disabled={isUpdating} onClick={() => AddToCart(SKU, qty.current.value)}>{translateResourceString("/Wishlists/Items/AddItemToCartButton")}</Button>

                        <div className="btn-group btn-block" role="group">
                            <button type="button" className="btn btn-bbs-blue btn-bbs-blue--outline btn-sm btn-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{translateResourceString("/Wishlists/Items/MoreItemOptions")}&nbsp;&nbsp;<i className="fa fa-chevron-down"></i></button>
                            <ul className="dropdown-menu" x-placement="bottom-start">
                                <li><button className="btn btn-link" onClick={(e) => prepareCopyData(e)}>{translateResourceString("/Wishlists/Items/CopyItemButton")}</button></li>
                                <li><button className="btn btn-link" onClick={(e) => prepareCopyData(e, true)}>{translateResourceString("/Wishlists/Items/MoveItemButton")}</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        
    );
};

WishlistProduct.propType = {
    AddToCartEndpoint: PropTypes.string.isRequired,
    Id: PropTypes.number,
    ImageUrl: PropTypes.string,
    Link: PropTypes.string,
    ListId: PropTypes.string.isRequired,
    ListIndex: PropTypes.number,
    ListPrice: PropTypes.string,
    Name: PropTypes.string,
    Price: PropTypes.string,
    PricesAreHidden: PropTypes.bool.isRequired,
    ProductCommand: PropTypes.shape({
        ListId: PropTypes.string.isRequired,
        ProductId: PropTypes.number.isRequired,
        RemoveAfter: PropTypes.bool.isRequired
    }).isRequired,
    Quantity: PropTypes.number,
    RemoveProduct: PropTypes.func,
    SKU: PropTypes.string,
    ToggleCopyModal: PropTypes.func
};

export default WishlistProduct;