
import {
  NEW_SHIPPING_OPTIONS,
  SELECT_SHIPPING_OPTION,
  NOTIFY_REFRESHING_SHIPPING_OPTIONS
} from '../../GlobalStateContainer'

export const ShippingOptions = function (state = [], action) {
  switch (action.type) {
    case NEW_SHIPPING_OPTIONS:
      return action.shippingOptions;
    default:
      return state;
  }
}

export const SelectedShippingOptionId = function (state = '', action) {
  switch (action.type) {
    case SELECT_SHIPPING_OPTION:
      return action.id;
    default:
      return state;
  }
}

export const isRefreshingShippingOptions = function (state = '', action) {
  switch (action.type) {
    case NOTIFY_REFRESHING_SHIPPING_OPTIONS:
      return true;
    case NEW_SHIPPING_OPTIONS:
      return false;
    default:
      return state;
  }
}

