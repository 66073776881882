import React from 'react'
import PropTypes from 'prop-types'

const ModalLink = ({
    ClassName,
    LinkText,
    SrText,
    ToggleModal
}) => {

    return (
        <div className='mb-2 dropright'>
            <button className='btn btn-default w-100' onClick={e => {
                e.preventDefault()
                ToggleModal(true)
            }}>
                <span className={ClassName}/>
                <span className="sr-only">{SrText}</span>
                <span>&nbsp;{LinkText}</span>
            </button>
        </div>
    );
};

ModalLink.propType = {
    ClassName: PropTypes.string,
    LinkText: PropTypes.string.isRequired,
    ToggleModal: PropTypes.func.isRequired
};

export default ModalLink;