import React from 'react'
import PropTypes from 'prop-types'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'

import { checkoutApp } from './GlobalStateContainer'
import CheckoutComponents from './Features/CheckoutComponents'
import { countriesStatesMap } from './Features/Address/propTypes'
import {
  arrayOfShapesWithIdOfString,
  arrayOfShapesWithIdOfAny  
} from '../util/propTypeShapes'

import mapTopLevelPropsToInitialStoreState from './GlobalStateContainer/mapTopLevelPropsToInitialStore'

// props should contain all data we want to initialize the app with
class Checkout extends React.Component{
  constructor(props) {
    super(props);
    
    let initialStoreState = mapTopLevelPropsToInitialStoreState(props);

    // Create store
    this.store = createStore(
      checkoutApp,
      initialStoreState,
      // Initialize cr
      composeWithDevTools(
        applyMiddleware(
          // Setup library to help with async actions
          thunkMiddleware,
          // Setup console action logger middleware
          createLogger()
      )));

  }

  // Make Store available to components, and instantiate checkout components
  render() {
    return (
      <Provider store={this.store}>
        <CheckoutComponents />
      </Provider>
    );
  }
}

Checkout.propTypes = {
  initialData: PropTypes.shape({
      BillingAddresses: arrayOfShapesWithIdOfString.isRequired,
      ShippingAddresses: arrayOfShapesWithIdOfString.isRequired,
      CartItems: arrayOfShapesWithIdOfAny.isRequired,
      PaymentOptions: arrayOfShapesWithIdOfString.isRequired,
      AllowBillingEqualsShipping: PropTypes.bool.isRequired,
      CouponViewModel: PropTypes.shape({
          Code: PropTypes.string,
          Description: PropTypes.string,
          HasError: PropTypes.bool
      }).isRequired,
    }
  ).isRequired,
    PhoneNumber: PropTypes.string,
    PhoneText: PropTypes.string,
    ReceiptUrl: PropTypes.string,
    CheckoutUrl: PropTypes.string,
    addAddressEndpoint: PropTypes.string,
    allowAddingBillingAddresses: PropTypes.bool,
    allowAddingShippingAddresses: PropTypes.bool,
    deleteAddressEndpoint: PropTypes.string,
    placeOrderEndpoint: PropTypes.string.isRequired,
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        Name: PropTypes.string.isRequired,
        Code: PropTypes.string.isRequired
      })).isRequired,
    ShippingAddressType: PropTypes.number.isRequired,
    BillingAddressType: PropTypes.number.isRequired,
    countriesStatesMap: PropTypes.objectOf(countriesStatesMap).isRequired,
  BraintreeClientSettings: PropTypes.shape({
    BraintreePaypalEnabled : PropTypes.bool.isRequired,
    BraintreePaypalCreditEnabled:  PropTypes.bool.isRequired,
    BraintreePaypalFlow: PropTypes.string.isRequired,
    BraintreePaymentOptionId: PropTypes.string.isRequired
    }).isRequired
}

export default Checkout
