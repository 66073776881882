import {
    ADD_COUPON,
    ADD_COUPON_ASYNC,
    REMOVE_COUPON,
    REMOVE_COUPON_ASYNC,
    UPDATING_COUPONS,
    UPDATING_COUPONS_ASYNC
} from '../../GlobalStateContainer'

import { notifyError } from '../UserMessaging'
import { fetchJson } from '../../GlobalStateContainer'
import { translateResourceString } from '../../../util/translationUtility';


const addingErrorMessage = () => "Error adding your coupon.";
const removingErrorMessage = () => "Error removing your coupon.";
const onAddCouponSuccess = (response) => {
    if (response.length > 0) {
        return notifyError(response[0], true);
    }
    return updateCouponsAsync();
}

const addCouponAsyncSpec = {
    
    endpoint: (state) => state.constants.navigation.addCouponEndpoint,
    init: { method: 'POST', credentials: 'same-origin' },
    notify: ({ code }) => { return { type: ADD_COUPON_ASYNC, code } },
    extract: (response) => response.json(),
    onSuccess: (response) => {
        if (response.Applied) {
            return onAddCouponSuccess(response);
        } else {
          var msg = response.Description && response.Description !== ""
            ? response.Description
            : addingErrorMessage()
          return notifyError(msg, response);
        }
    },
    onError: (diagMsg) => notifyError(translateResourceString("/Checkout/Coupons/CouponAdddingError"), diagMsg)
}

export const addCouponAsync = fetchJson(addCouponAsyncSpec);


const onRemoveCouponSuccess = (response) => {
    if (!response) {
        return notifyError(translateResourceString("/Checkout/Coupons/CouponRemovingError"), true);
    }
    return updateCouponsAsync();
}

const removeCouponAsyncSpec = {
    endpoint: (state) => state.constants.navigation.removeCouponEndpoint,
    init: { method: 'POST', credentials: 'same-origin' },
    notify: ({ code }) => { return { type: REMOVE_COUPON_ASYNC, code } },
    extract: (response) => response.json(),
    onSuccess: (response) => onRemoveCouponSuccess(response),
    onError: (diagMsg) => notifyError(translateResourceString("/Checkout/Coupons/CouponRemovingError"), diagMsg)
};

export const removeCouponAsync = fetchJson(removeCouponAsyncSpec);


const updateCouponSuccess = (responseObject) => {
    const coupons = responseObject;
    return { type: UPDATING_COUPONS, coupons };
}

const updateCouponsAsyncFetchSpec = {
    endpoint: (state) => state.constants.navigation.couponsEndpoint,
    init: { method: 'GET', credentials: 'same-origin' },
    notify: () => { return { type: UPDATING_COUPONS_ASYNC } },
    extract: (response) => response.json(),
    onSuccess: (response) => updateCouponSuccess(response),
    onError: (diagMsg) => notifyError(translateResourceString("/Checkout/Coupons/CouponClearingError"), diagMsg)
}

export const updateCouponsAsync = fetchJson(updateCouponsAsyncFetchSpec)