import React from 'react';
import CombinedSearchResults from './Features/Combined/CombinedSearchResults';
import createHistory from 'history/createBrowserHistory';
import fetch from 'cross-fetch';
import {
    ARTICLES,
    PRODUCTS
} from './constants/index.js';
import {
    buildAttributesQueryString,
    buildQueryString,
    serialize
} from './Infrastructure/urlBuilder.js';
import {
    buildStateFromModel,
    buildSelectedAttributesFromModel,
    buildFacetNamesFromModel
} from './Infrastructure/modelBuilder.js';
import { trackClick } from './Infrastructure/find.js';
import ProductSearchResults from './Features/Products/ProductSearchResults';
import WithLoading from './components/WithLoading';
import { addResourceStrings, translateResourceString } from '../util/translationUtility'

class SearchApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = buildStateFromModel(props);

        this.onAttributeChange = this.onAttributeChange.bind(this);
        this.setProducts = this.updateState.bind(this);
        this.onClearAttributes = this.onClearAttributes.bind(this);
        this.onProductCurrentItemsPerPageChange = this.onProductCurrentItemsPerPageChange.bind(this);
        this.onProductCurrentSortByChange = this.onProductCurrentSortByChange.bind(this);
        this.onProductCurrentPageIndexChange = this.onProductCurrentPageIndexChange.bind(this);
        this.onArticleCurrentItemsPerPageChange = this.onArticleCurrentItemsPerPageChange.bind(this);
        this.onArticleCurrentPageIndexChange = this.onArticleCurrentPageIndexChange.bind(this);
        this.onChangeView = this.onChangeView.bind(this);
        this.onResultClick = this.onResultClick.bind(this);
        this.onShowMobileFacetsChange = this.onShowMobileFacetsChange.bind(this);
        this.onShowMobileFacetsToggle = this.onShowMobileFacetsToggle.bind(this);
        this.onToggleFacetExpanded = this.onToggleFacetExpanded.bind(this);
        this.anyProductsOrArticles = this.anyProductsOrArticles.bind(this);
        this.hasSearchString = this.hasSearchString.bind(this);
        this.isCategory = this.isCategory.bind(this);
        this.apiEndpoint = props.ApiEndpoint;
        this.clickTrackingApiEndpoint = props.ClickTrackingApiEndpoint;
        this.trackId = props.TrackId;
        this.twoLetterLanguageCode = props.TwoLetterLanguageCode;
        this.siteDefinitionId = props.SiteDefinitionId;
        this.onToggleShowMoreFacet = this.onToggleShowMoreFacet.bind(this);

        // Category - not in state
        this.categoryName = props.Name;
        this.contentLinkId = props.ContentLinkId;
        this.categoryImageUrl = props.ImageUrl;
        this.categoryImageAltText = props.ImageAltText;
        this.categoryDescription = props.Description;


    }

    componentDidMount() {
        require('./SearchApp.css');
        this.history =  createHistory();
        this.history.listen((location, action) => {
            if (location.state && location.state.view) {
                this.setState({ view: location.state.view });
            }
            this.fetchProductsUsingUrl(location.search);
        });
    }

    updateState(searchResults) {
        this.setState({
            attributes: searchResults.Products.ProductFilters && searchResults.Products.ProductFilters.Attributes ? searchResults.Products.ProductFilters.Attributes : [],
            products: searchResults.Products.ProductItems ? searchResults.Products.ProductItems : [],
            productPaginationViewModel: searchResults.Products && searchResults.Products.PaginationViewModel ? searchResults.Products.PaginationViewModel : null,
            articlePaginationViewModel: searchResults.Articles && searchResults.Articles.PaginationViewModel ? searchResults.Articles.PaginationViewModel : null,
            selectedAttributes: searchResults.Products && searchResults.Products.ProductFilters && searchResults.Products.ProductFilters.Attributes ? buildSelectedAttributesFromModel(searchResults.Products.ProductFilters.Attributes) : [],
            isLoading: false,
            articles: searchResults.Articles && searchResults.Articles.ArticleItemViewModels ? searchResults.Articles.ArticleItemViewModels : []
        });
    }

    updateUrl({ searchString, selectedAttributes, productPaginationViewModel, articlePaginationViewModel, view, categoryContentLinkId }) {
        const queryString = buildQueryString(searchString, selectedAttributes, productPaginationViewModel, articlePaginationViewModel, view);
        // Going to let history listener take care of it
        this.history.push({
            search: queryString,
            state: { view: view }
        });
    }

    fetchProductsUsingUrl(queryString) {
        if(this.contentLinkId)
        {
            if(queryString && queryString.indexOf("?") > -1)
            {
                queryString = `${queryString}&contentLinkId=${this.contentLinkId}`;
            }
            else
            {
                queryString = `?contentLinkId=${this.contentLinkId}`;
            }   
        }
        this.setState({ isLoading: true });
        const url = `${this.apiEndpoint}${queryString}`;
        fetch(url)
            .then(response => response.json())
            .then(result => this.updateState(result));
    }

    onAttributeChange(name, value) {
        let found = false;
        let selectedAttributes = this.state.selectedAttributes;
        selectedAttributes.forEach((selectedAttribute, i) => {
            if (selectedAttribute.name === name && selectedAttribute.value === value) {
                found = true;

                // Remove it
                selectedAttributes.splice(i, 1);
            }
        });

        if (!found) {
            // Add it
            selectedAttributes.push({
                name: name,
                value: value
            });
        }

        const changes = {
            selectedAttributes: selectedAttributes
        };

        const newOptions = { ...this.getCurrentState(), ...changes };
        this.updateUrl(newOptions);
    }

    onProductCurrentSortByChange(requestedSortBy) {
        let productPaginationViewModel = this.state.productPaginationViewModel;
        productPaginationViewModel.CurrentSortBy = requestedSortBy;
        const newOptions = { ...this.getCurrentState(), productPaginationViewModel };
        this.updateUrl(newOptions);
    }

    onClearAttributes() {
        const changes = { selectedAttributes: [] };
        const newOptions = { ...this.getCurrentState(), ...changes };
        this.updateUrl(newOptions);
    }

    onProductCurrentItemsPerPageChange(requestedItemsPerPage) {
        // Need to possibly update the page request too
        const { productPaginationViewModel } = this.state;
        const firstProductNumberOnNewPage = productPaginationViewModel.CurrentPageIndex * requestedItemsPerPage + 1;
        const requestedPageIndex = firstProductNumberOnNewPage > productPaginationViewModel.ResultsTotal
            ? Math.floor(productPaginationViewModel.ResultsTotal / requestedItemsPerPage)
            : productPaginationViewModel.CurrentPageIndex;
        productPaginationViewModel.CurrentPageIndex = requestedPageIndex;
        productPaginationViewModel.CurrentItemsPerPage = requestedItemsPerPage;
        const newOptions = { ...this.getCurrentState(), productPaginationViewModel };
        this.updateUrl(newOptions);
    }

    onProductCurrentPageIndexChange(requestedPageIndex) {
        const { productPaginationViewModel } = this.state;
        productPaginationViewModel.CurrentPageIndex = requestedPageIndex;
        const newOptions = { ...this.getCurrentState(), productPaginationViewModel };
        this.updateUrl(newOptions);
    }

    onArticleCurrentItemsPerPageChange(requestedItemsPerPage) {
        // Need to possibly update the page request too
        const { articlePaginationViewModel } = this.state;
        const firstArticleNumberOnNewPage = articlePaginationViewModel.CurrentPageIndex * requestedItemsPerPage + 1;
        const requestedPageIndex = firstArticleNumberOnNewPage > articlePaginationViewModel.ResultsTotal
            ? Math.floor(articlePaginationViewModel.ResultsTotal / requestedItemsPerPage)
            : articlePaginationViewModel.CurrentPageIndex;
        articlePaginationViewModel.CurrentPageIndex = requestedPageIndex;
        articlePaginationViewModel.CurrentItemsPerPage = requestedItemsPerPage;
        const newOptions = { ...this.getCurrentState(), articlePaginationViewModel };
        this.updateUrl(newOptions);
    }

    onArticleCurrentPageIndexChange(requestedPageIndex) {
        const { articlePaginationViewModel } = this.state;
        articlePaginationViewModel.CurrentPageIndex = requestedPageIndex;
        const newOptions = { ...this.getCurrentState(), articlePaginationViewModel };
        this.updateUrl(newOptions);
    }

    /**
     * Gets variables from state and returns them.
     * @returns {object} State in object.
     */
    getCurrentState() {
        const { searchString, selectedAttributes, productPaginationViewModel, articlePaginationViewModel, view } = this.state;
        return {
            searchString, selectedAttributes, productPaginationViewModel, articlePaginationViewModel, view
        };
    }

    /**
     * Updates current view (Products vs Articles)
     * @param {string} view Current View Name.
     * @returns {void}
     */
    onChangeView(view) {
        const changes = { view: view };
        const newOptions = { ...this.getCurrentState(), ...changes };
        this.updateUrl(newOptions);
    }

    /**
     * Tracking for Find
     * @param {string} hitId HitId for Find result.
     * @returns {void}
     */
    onResultClick(hitId) {
        // Call tracking api
        if (typeof this.state.findClickTrackingViewModel !== "undefined") {
            const { TrackId, TwoLetterLanguageCode, SiteDefinitionId, ClickTrackingApiEndpoint, SearchTerm } = this.state.findClickTrackingViewModel;
            trackClick(ClickTrackingApiEndpoint, hitId, TrackId, TwoLetterLanguageCode, SiteDefinitionId, SearchTerm);
        }
    }

    onShowMobileFacetsChange(showMobileFacets) {
        this.setState({ showMobileFacets });
    }

    onShowMobileFacetsToggle() {
        const { showMobileFacets } = this.state;
        this.setState({ showMobileFacets: !showMobileFacets });
    }

    onToggleFacetExpanded(facetName) {
        const { expandedFacets } = this.state;
        const index = expandedFacets.indexOf(facetName);
        if (index > -1) {
            expandedFacets.splice(index, 1);
        } else {
            expandedFacets.push(facetName);
        }
        this.setState({ expandedFacets });
    }

    onToggleShowMoreFacet(facetName) {
        const { showMoreFacets } = this.state;
        const index = showMoreFacets.indexOf(facetName);
        if (index > -1) {
            showMoreFacets.splice(index, 1);
        } else {
            showMoreFacets.push(facetName);
        }
        this.setState({ showMoreFacets });
    }

    anyProductsOrArticles() {
        const {
            articles,
            products
        } = this.state;

        const anyArticles = articles && articles.length > 0;
        const anyProducts = products && products.length > 0;
        return anyArticles || anyProducts;
    }

    hasSearchString() {
        const { searchString } = this.state;
        return searchString && searchString.length > 0;
    }

    isCategory() {
        return this.categoryName && this.categoryName.length > 0;
    }

    render() {
        const {
            searchString,
            attributes,
            products,
            productPaginationViewModel,
            isLoading,
            view,
            articles,
            articlePaginationViewModel,
            suggestions,
            showMobileFacets,
            expandedFacets,
            showMoreFacets,
            promotionalArea
        } = this.state;

        return (
            <div className="search-app">
                {/* <div className="hidden-md hidden-lg">
                    {showMobileFacets &&
                        <div
                            className="overlay hidden-md hidden-lg"
                            onClick={() => this.onShowMobileFacetsChange(false)}
                        />
                    }
                </div> */}
                <div className="search-app-inner">
                    {this.hasSearchString() &&
                        <div className="row">
                            <div className="col-md-12">
                                <h1>
                                    Search Results For {searchString}:
                                </h1>
                            </div>
                        </div>
                    }
                    {!this.anyProductsOrArticles() && this.hasSearchString() &&
                        <div className="search-did-not-match">
                            <p>
                                Your search <strong>"{searchString}"</strong> did not match any products or articles.
                            </p>
                        </div>
                    }
                    {suggestions && suggestions.length > 0 &&
                        <div className="row">
                            <div className="col-md-12">
                                <div id="did-you-mean">
                                    <p>
                                        Did you mean {suggestions.map((suggestion, i) => {
                                            return (
                                                <span key={i}>
                                                    <a href={suggestion.Url}>{suggestion.Text}</a>
                                                    {i + 1 < suggestions.length ? ", " : ""}
                                                </span>
                                            );
                                        })}
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    {this.isCategory() ?
                        <ProductSearchResultsWithLoading
                            attributes={attributes}
                            onAttributeChange={this.onAttributeChange}
                            onClearAttributes={this.onClearAttributes}
                            onCurrentItemsPerPageChange={this.onProductCurrentItemsPerPageChange}
                            onCurrentSortByChange={this.onProductCurrentSortByChange}
                            onCurrentPageIndexChange={this.onProductCurrentPageIndexChange}
                            paginationViewModel={productPaginationViewModel}
                            products={products}
                            recommendedProducts={this.state.recommendedProducts}
                            onResultClick={this.onResultClick}
                            isLoading={isLoading}
                            onShowMobileFacetsChange={this.onShowMobileFacetsChange}
                            expandedFacets={expandedFacets}
                            onToggleFacetExpanded={this.onToggleFacetExpanded}
                            categoryName={this.categoryName}
                            categoryImageUrl={this.categoryImageUrl}
                            categoryImageAltText={this.categoryImageAltText}
                            categoryDescription={this.categoryDescription}
                            showMoreFacets={showMoreFacets}
                            onToggleShowMoreFacet={this.onToggleShowMoreFacet}
                            promotionalArea={promotionalArea}
                        />
                        : this.anyProductsOrArticles() ?
                            <CombinedSearchResults
                                articles={articles}
                                articlePaginationViewModel={articlePaginationViewModel}
                                onArticleCurrentItemsPerPageChange={this.onArticleCurrentItemsPerPageChange}
                                onArticleCurrentPageIndexChange={this.onArticleCurrentPageIndexChange}
                                onResultClick={this.onResultClick}
                                attributes={attributes}
                                onAttributeChange={this.onAttributeChange}
                                onClearAttributes={this.onClearAttributes}
                                onProductCurrentItemsPerPageChange={this.onProductCurrentItemsPerPageChange}
                                onProductCurrentSortByChange={this.onProductCurrentSortByChange}
                                onProductCurrentPageIndexChange={this.onProductCurrentPageIndexChange}
                                productPaginationViewModel={productPaginationViewModel}
                                products={products}
                                view={view}
                                onChangeView={this.onChangeView}
                                isLoading={isLoading}
                                onShowMobileFacetsChange={this.onShowMobileFacetsChange}
                                expandedFacets={expandedFacets}
                                onToggleFacetExpanded={this.onToggleFacetExpanded}
                                showMoreFacets={showMoreFacets}
                                onToggleShowMoreFacet={this.onToggleShowMoreFacet}
                            />
                            : this.hasSearchString() &&
                            <div>
                                <p className="search-suggestions-heading">
                                    <strong>Try something like:</strong>
                                </p>
                                <ul className="search-suggestions">
                                    <li>Using more general terms</li>
                                    <li>Checking your spelling</li>
                                </ul>
                            </div>
                    }
                </div>
            </div>
        );
    }
}

const ProductSearchResultsWithLoading = WithLoading(ProductSearchResults);

export default SearchApp;