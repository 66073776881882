import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import ModalLink from './ModalLink'
import AddToModal from './AddToModal'
import { addResourceStrings, translateResourceString } from '../util/translationUtility'

const AddToWishlistApp = ({
    Name,
    SKU,
    ImageUrl,
    Wishlists,
    AddItemEndpoint,
    AddListEndpoint,
    UserIsAuthorized,
    LoginLink,
    LocalizationItems
}) => {
    addResourceStrings(LocalizationItems);

    const [Lists, updateLists] = useState(Wishlists)
    const [ModalIsOpen, toggleModal] = useState(false)
    const [linkClass, setLinkClass] = useState('fa fa-bookmark')
    const [srText, setSrText] = useState('')
    const [linkText, setLinkText] = useState(translateResourceString("/Product/AddToListButton"))

    return (
        <Fragment>
            <ModalLink
                ClassName={linkClass}
                LinkText={linkText}
                SrText={srText}
                ToggleModal={toggleModal}
             />

            <AddToModal
                Lists={Lists}
                UpdateLists={updateLists}
                Name={Name}
                ImageUrl={ImageUrl}
                SKU={SKU}
                ModalIsOpen={ModalIsOpen}
                ToggleModal={toggleModal}
                AddItemEndpoint={AddItemEndpoint}
                CreateListEndpoint={AddListEndpoint}
                SetLinkClass={setLinkClass}
                SetSrText={setSrText}
                SetLinkText={setLinkText}
                UserIsAuthorized={UserIsAuthorized}
                LoginLink={LoginLink} />
        </Fragment>
    );
};

AddToWishlistApp.propType = {
    Wishlists: PropTypes.arrayOf(
        PropTypes.shape({
            Name: PropTypes.string
        })
    ).isRequired,
    AddItemEndpoint: PropTypes.string.isRequired,
    AddListEndpoint: PropTypes.string.isRequired,
    UserIsAuthorized: PropTypes.bool.isRequired,
    LoginLink: PropTypes.string.isRequired
};

export default AddToWishlistApp;