import React from 'react';
import ProductTile from './ProductTile';
import PropTypes from 'prop-types';
import WithClickTracking from '../../components/WithClickTracking';

const ProductTileWithClickTracking = WithClickTracking(ProductTile);

const ProductTileContainer = ({ products, onResultClick }) =>
    <div className="product-list">
        <div className="row">
            {products.map((product, i) => {
                return (
                    <div key={i} className="col-sm-6 col-md-4">
                        <ProductTileWithClickTracking 
                            name={product.Name}
                            url={product.Url}
                            image={product.Image}
                            HitId={product.HitId}
                            onResultClick={onResultClick}
                            description={product.Description} 
                        />
                    </div>
                );
            })}
        </div>
    </div>;

ProductTileContainer.propTypes = {
    products: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string,
        Image: PropTypes.shape({
            Url: PropTypes.string,
            Alt: PropTypes.string
        }),
        Description: PropTypes.string
    }))
};

export default ProductTileContainer;