import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery'
import { translateResourceString } from '../../util/translationUtility';

import { CheckoutCouponContainer } from './Coupon'
import { CartListContainer } from './Cart'
import { CheckoutShippingAddress } from './Address'
import { CheckoutSummaryContainer } from './Summary'
import { CheckoutCompleteContainer } from './PlaceOrder'
import { MessagesList } from './UserMessaging'
import { CheckoutShipment } from './ShippingMethod'
import { CheckoutPayment } from './PaymentMethod'
import { CheckoutBillingAddress } from './Address'
import CheckoutPromoGiftCard from './CheckoutPromoGiftCard'

class Components extends Component {

    componentDidMount() {

        // BBS DDD This should be refactored and moved into component animations, or
        // a renderProp component
        // https://reactjs.org/docs/render-props.html

        let docWidth = 0, docTop = 0;
        if (typeof document !== 'undefined' && document.documentElement) {

            docWidth = document.documentElement.clientWidth;
            docTop = document.documentElement.getBoundingClientRect().top
        } else {
            if (typeof window.innerWidth !== 'undefined' && window.innerWidth)
                docWidth = window.innerWidth;
        }
        const minPortWidthPx = 767;
        if (docWidth > minPortWidthPx) {
            // BBS DDD 2018-02-19 I broke down and used jquery. Yuck.
            const affixedElt = document.getElementById('right-summary');
            const conteainerElt = document.getElementById('right-summary-container');
            affixedElt.setAttribute("data-spy", "affix");
            const containerClientRect = conteainerElt ? conteainerElt.getBoundingClientRect() : { top: 0, height: 0 };
            affixedElt.setAttribute("data-offset-top", containerClientRect.top - docTop);
            affixedElt.setAttribute("data-offset-bottom", containerClientRect.top + containerClientRect.height - affixedElt.getBoundingClientRect().height);
            let affixedWidth = affixedElt.getBoundingClientRect().width;
            affixedElt.style.width = affixedWidth;
            $('#right-summary').on('affix.bs.affix', (e) => { e.target.setAttribute('style', `top: 0px; width: ${affixedWidth}px`); });
            $('#right-summary').on('affix-top.bs.affix', (e) => { e.target.setAttribute('style', ` width: ${affixedWidth}px`); });

            if (typeof window !== 'undefined' && typeof window.addEventListener !== 'undefined') {
                window.addEventListener('resize', (e) => {
                    // BBS DDD 2018-02-19 This code attempts to resize the width of the affixed
                    // element when the window resizes, but nothing happens
                    const hasAffix = affixedElt.classList.contains('affix');
                    if (hasAffix) {
                        affixedElt.classList.remove('affix');
                        affixedElt.classList.add('affix-top');
                    }
                    affixedWidth = affixedElt.getBoundingClientRect().width;
                    if (hasAffix) {
                        affixedElt.classList.remove('affix-top');
                        affixedElt.classList.add('affix');
                    }
                })
            }
        }
    }

    render() {
        if (this.props.checkoutIsEmpty) {
            return (
                <div className="checkout">
                    <div className="col-12 text-center">
                        <p>{translateResourceString("/Checkout/EmptyCartMessage")}</p>
                        <p><a href="/">{translateResourceString("/Checkout/BackToHomeLink")}</a></p>
                    </div>
                </div>
            )
        }
        return (
            <div className="checkout row">
                <div className="col-12 d-block d-md-none">
                    <CheckoutCompleteContainer />
                    <CheckoutSummaryContainer />
                    <MessagesList />
                </div>

                <div className="col-md-8" style={{ marginBottom: 20 }}>
                    <CheckoutShippingAddress />
                    <CheckoutShipment />
                    <CheckoutCouponContainer/>
                    <CheckoutPayment />
                    <CheckoutBillingAddress />
                    <CheckoutPromoGiftCard />
                    <CartListContainer />
                    <CheckoutCompleteContainer />
                </div>

                <div className="col-md-4 d-none d-md-block" id='right-summary-container'>
                    <div id='right-summary'>
                        <CheckoutSummaryContainer />
                        <CheckoutCompleteContainer />
                        <MessagesList />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    checkoutIsEmpty: state.entities.CartItems.length < 1
});

export default connect(mapStateToProps)(Components)