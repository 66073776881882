import React from "react";
import { CheckoutSection, hiddenAnchorStyle, OptionListItemRadio } from "../../components";
import { translateResourceString } from '../../../util/translationUtility';
import { Fade } from "../../componentAnimations";

const CheckoutShipmentPresentation = (
  {
    shippingOptions,
    selectedId,
    canChange,
    onClickChange,
    isCollapsed,
    onSelected,
    isPendingRefresh
  }) => {
  const selectedOption = shippingOptions.find(opt => opt.Id === selectedId)
  return (
    <CheckoutSection label='Shipping Method' hidden={false}>
      {isPendingRefresh
        ? <span className="fa fa-spinner fa-spin"/>
        : (
          <React.Fragment>
            {!!selectedOption &&
            <div style={{ lineHeight: 2 }}>
              <button
                className="btn btn-link"
                style={hiddenAnchorStyle}
                onClick={(e) => onClickChange(e)}
              >
                {selectedOption.NameWithPrice}
                {canChange &&
                                  <span style={{ marginLeft: 20, color: '#004af4', fontWeight: 600 }}>
                  {isCollapsed ? "Change" : "Done"}
              </span>}
              </button>
            </div>}
            {shippingOptions.length === 0 &&
                          <div>{translateResourceString("/Checkout/ShippingMethod/NoShippingMethodsAvailable")}</div>}

            <Fade inProp={!isCollapsed}>
              <div>{shippingOptions.map(sm => (
                <OptionListItemRadio
                  id={sm.Id}
                  key={sm.Id}
                  isSelected={(sm.Id === selectedId)}
                  onClick={onSelected}
                  label={sm.NameWithPrice}
                />))}
              </div>
            </Fade>
          </React.Fragment>
        )}
    </CheckoutSection>
  );
};
        
export default CheckoutShipmentPresentation