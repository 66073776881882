import React from 'react'
import PropTypes from 'prop-types'

const StateInput = ({ states, value, onChange, isRequired }) => {
  const name = "state";
  if (states && states.length > 0) {
    return (
      <select
        name={name}
        value={value}
        onChange={onChange}
        className='form-control'
        required={isRequired(name)}
      >
        <option key="no state" value="" disabled={true}>Select</option>
        {states.map((state) =>
          <option key={state} value={state}>{state}</option>
        )}
      </select>
    );
  } else {
    return (
        <input type="text" name={name} value={value} onChange={onChange} className="form-control" />
    );
  }
}

StateInput.propTypes = {
  states: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

class StateDropdownWrapper extends React.Component {
  _handleChange(event) {
    let eventValue = event.target.value;
    let states = this.props.countriesStatesMap[this.props.CountryCode];
    if (!states || states.length === 0 || states.includes(eventValue)) {
      this.props.onChange(event);
    }
    event.preventDefault();
  }
  
  render() {
    return (
      <StateInput
        onChange={(event) => this._handleChange(event)}
        states={this.props.countriesStatesMap[this.props.CountryCode] || []}
        value={this.props.value}
        isRequired={this.props.isRequired}
      />
    );
  }
}

StateDropdownWrapper.propTypes = {
  countryCode: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  countriesStatesMap: PropTypes.object.isRequired,
  value: PropTypes.string
}

export default StateDropdownWrapper;