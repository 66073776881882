import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

class ProductTile extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        require('./ProductTile.css');
    }

    render() {
        const {
            name,
            url,
            priceViewModel,
            image,
            onClick,
            description
        } = this.props;

        return (
            <div className="product-tile">
                <div className="panel panel-default">
                    <div className="product-tile-inner">
                        <div className="row">
                            <div className="col-sm-12 col-6 col-md-12 item-mage">
                                <div>
                                    <a
                                        title={name}
                                        href={url}
                                        onClick={onClick}
                                    >
                                        <img
                                            src={image.Url}
                                            alt={image.Alt}
                                            className="img-fluid"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-sm-12 col-6 col-md-12 item-description">
                                <a
                                    title={name}
                                    href={url}
                                    onClick={onClick}
                                >
                                    <h4>{name}</h4>
                                </a>
                                <span className="description">
                                    {description}
                                </span>
                                <div className="item-view-item">
                                    <Button
                                        className="item-view-item-button"
                                        href={url}
                                        onClick={onClick}
                                    >
                                        View Item
                                </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    }
}


ProductTile.propTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    priceViewModel: PropTypes.shape({
        RegularPrice: PropTypes.number,
        SalePrice: PropTypes.number,
        IsSalePrice: PropTypes.bool
    }),
    image: PropTypes.shape({
        Url: PropTypes.string,
        Alt: PropTypes.string
    }),
    description: PropTypes.string
};

export default ProductTile;