import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

// Uncomment to package css transpiled from index.scss into index.css
//import './index.css';
import './shims/polyfills';

import checkoutApp from './Components/checkout';
import SearchApp from './Components/search/SearchApp';
import TypeaheadApp from './Components/typeahead/TypeaheadApp';
import reorderApp from './Components/reorder';
import WishlistsApp from './Components/wishlists/WishlistsApp';
import AddToWishlistApp from './Components/addtowishlist/AddToWishlistApp';
import quickOrderApp from './Components/quickorder';

global.React          = React;
global.ReactDOM       = ReactDOM;
global.ReactDOMServer = ReactDOMServer;

global.Checkout = checkoutApp;
global.Search = SearchApp;
global.Typeahead = TypeaheadApp
global.Reorder = reorderApp;
global.Wishlists = WishlistsApp;
global.AddToWishlist = AddToWishlistApp;
global.QuickOrder = quickOrderApp