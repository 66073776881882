import React from 'react'
import { connect } from 'react-redux'
import deepEquals from "deep-equal";

import { mapStateToPlaceOrderDetails } from '../PlaceOrder'
import { notifyError } from '../UserMessaging'

import CheckoutSummary from './CheckoutSummary'
import { refreshSummary } from "./checkoutSummaryActions";
import { translateResourceString } from '../../../util/translationUtility';

function dependenciesChanged(props, prevProps) {
  const shippingMethodChanged = props.selectedShippingOptionId !== prevProps.selectedShippingOptionId;
  const shippingAddressChanged = props.selectedShippingAddressId !== prevProps.selectedShippingAddressId;
  return shippingMethodChanged || 
    shippingAddressChanged || 
    !deepEquals(props.cart, prevProps.cart) ||
    !deepEquals(props.coupons, prevProps.coupons);
}

class CheckoutSummaryContainer extends React.Component {
  componentDidUpdate(prevProps) {
    if (dependenciesChanged(this.props, prevProps)) {
      this.props.refreshSummary(this.props.placeOrderDetails)
    }
  }
  
  componentDidMount() {
    this.props.refreshSummary(this.props.placeOrderDetails);
  }

  render() {
    return React.createElement(CheckoutSummary,
      {
        summary: this.props.summary,
        isRefreshing: this.props.isRefreshing
      })
  }
  
}

const mapCheckoutSummaryStateToProps = state => {
  return {
    summary: state.entities.Summary,
    placeOrderDetails: mapStateToPlaceOrderDetails(state),
    cart: state.entities.CartItems,
    isRefreshing: state.entities.isRefreshingSummary,
    selectedShippingOptionId: state.entities.SelectedShippingOptionId,
    selectedShippingAddressId: state.entities.SelectedShippingAddressId,
    coupons: state.entities.Coupons
  }
}

const getSummaryErrorMessage = translateResourceString("/Checkout/Summary/ErrorRefreshing");

const mapCheckoutSummaryDispatchToProps = dispatch => {
  return {
    onGetSummaryError: (diagnosticMessage) => 
      dispatch(notifyError(getSummaryErrorMessage, diagnosticMessage)),
    refreshSummary: (placeOrderDetails) => 
      dispatch(refreshSummary(placeOrderDetails))
  }
}

const ConnectedCheckoutSummary = connect(mapCheckoutSummaryStateToProps, mapCheckoutSummaryDispatchToProps)(CheckoutSummaryContainer)

export default ConnectedCheckoutSummary