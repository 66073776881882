import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TypeaheadEntry = ({ href, name, selected, imageUrl }) => {
    const typeaheadEntryClass = classNames(
        'typeahead-result-wrapper',
        { 'selected': selected },
    );
    return <div className={typeaheadEntryClass}>
        <a href={href}>
            {imageUrl &&
                <img 
                    src={imageUrl} 
                    alt={name}
                />
            }
            {name}</a>
    </div>
}

TypeaheadEntry.propTypes = {
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    imageUrl: PropTypes.string
};

export default TypeaheadEntry;