import React, { Fragment, useState } from 'react'
import { Tabs } from 'react-tabs'
import PropTypes from 'prop-types'
import WishlistsTabList from './WishlistsTabList'
import WishlistsTabPanels from './WishlistsTabPanels'
import WishlistsTab from './WishlistsTab'
import NewListModal from './Features/NewList/NewListModal'
import ListsModal from './Features/ListsModal/ListsModal'
import EditListModal from './Features/EditList/EditListModal'
import CopyModal from './Features/CopyModal/CopyModal'
import PrintModal from './Features/PrintModal/PrintModal'
import Notification from './Components/Notification'
import { addResourceStrings, translateResourceString } from '../util/translationUtility'

const WishlistsApp = ({
    Wishlists,
    AddToCartEndpoint,
    AddListToCartEndpoint,
    UpdateProductEndpoint,
    AddItemEndpoint,
    RemoveItemEndpoint,
    CreateListEndpoint,
    RenameListEndpoint,
    DeleteListEndpoint,
    LocalizationItems
}) => {
    const [Lists, updateLists] = useState(Wishlists)
    const [NewListModalIsOpen, toggleNewListModal] = useState(false)
    const [ListsModalIsOpen, toggleListsModal] = useState(false)
    const [CopyModalIsOpen, toggleCopyModal] = useState(false)
    const [PrintModalIsOpen, togglePrintModal] = useState(false)
    const [PricesAreHidden, hidePrices] = useState(false)
    const [{ EditListModalIsOpen, ListName }, toggleEditListModal] = useState({ EditListModalIsOpen: false, ListName: "" })
    const [ActiveTabIndex, selectActiveTab] = useState(0)
    const [{ ListId, ProductId, RemoveAfter }, ProductCommand] = useState({ ListId: 0, ProductId: 0, RemoveAfter: false })
    const [{ IsOpen, Message, TargetListName }, toggleNotification] = useState({ IsOpen: false, Message: "", TargetListName: "" })
    
    addResourceStrings(LocalizationItems);

    return (
        <Fragment>
            <Notification
                ToggleNotification={toggleNotification}
                IsOpen={IsOpen}
                ListName={TargetListName} 
                Text={Message} />

            <Tabs className="row" selectedIndex={ActiveTabIndex} onSelect={index => selectActiveTab(index)}>
                {WishlistsTabList({
                    Lists: Lists,
                    ToggleNewListModal: toggleNewListModal,
                    ToggleEditListModal: toggleEditListModal,
                    ActiveTabIndex: ActiveTabIndex
                })}
                {WishlistsTabPanels({
                    Lists: Lists,
                    ToggleEditListModal: toggleEditListModal,
                    ToggleListsModal: toggleListsModal,
                    UpdateLists: updateLists,
                    ToggleCopyModal: toggleCopyModal,
                    ProductCommand: ProductCommand,
                    TogglePrintModal: togglePrintModal,
                    PricesAreHidden: PricesAreHidden,
                    AddToCartEndpoint: AddToCartEndpoint,
                    AddListToCartEndpoint: AddListToCartEndpoint,
                    UpdateProductEndpoint: UpdateProductEndpoint,
                    RemoveItemEndpoint: RemoveItemEndpoint
                })}
            </Tabs>

            <NewListModal
                Lists={Lists}
                UpdateLists={updateLists}
                IsOpen={NewListModalIsOpen}
                ToggleNewListModal={toggleNewListModal}
                SelectActiveTab={selectActiveTab}
                CreateListEndpoint={CreateListEndpoint} />

            <EditListModal
                Lists={Lists}
                ListName={ListName}
                UpdateLists={updateLists}
                EditListModalIsOpen={EditListModalIsOpen}
                ToggleEditListModal={toggleEditListModal}
                CreateListEndpoint={CreateListEndpoint}
                RenameListEndpoint={RenameListEndpoint}
                DeleteListEndpoint={DeleteListEndpoint}
                AddItemEndpoint={AddItemEndpoint} />

            <ListsModal
                Lists={Lists}
                UpdateLists={updateLists}
                ListsModalIsOpen={ListsModalIsOpen}
                ToggleListsModal={toggleListsModal}
                ActiveTabIndex={ActiveTabIndex}
                SelectActiveTab={selectActiveTab}
                CreateListEndpoint={CreateListEndpoint} />

            <CopyModal
                Lists={Lists}
                UpdateLists={updateLists}
                CopyModalIsOpen={CopyModalIsOpen}
                ToggleCopyModal={toggleCopyModal}
                ProductCommand={{ ListId, ProductId, RemoveAfter }}
                ToggleNotification={toggleNotification}
                CopyItemNotificationText={translateResourceString("/Wishlists/CopyItemNotificationText")}
                MoveItemNotificationText={translateResourceString("/Wishlists/MoveItemNotificationText")}
                AddItemEndpoint={AddItemEndpoint}
                RemoveItemEndpoint={RemoveItemEndpoint}
                CreateListEndpoint={CreateListEndpoint} />

            <PrintModal
                PrintModalIsOpen={PrintModalIsOpen}
                TogglePrintModal={togglePrintModal}
                HidePrices={hidePrices} />
        </Fragment>
    );
};

WishlistsTab.tabsRole = 'Tab';
WishlistsTabList.tabsRole = 'TabList';
WishlistsTabPanels.tabsRole = 'TabPanel';

WishlistsApp.propType = {
    Wishlists: PropTypes.arrayOf(
        PropTypes.shape({
            Name: PropTypes.string,
            Items: PropTypes.arrayOf(
                PropTypes.shape({
                    SKU: PropTypes.string,
                    Name: PropTypes.string,
                    ImageUrl: PropTypes.string,
                    Link: PropTypes.string,
                    StockAvailability: PropTypes.string,
                    Price: PropTypes.string,
                    ListPrice: PropTypes.string,
                    Quantity: PropTypes.number,
                    TotalPrice: PropTypes.string,
                    Id: PropTypes.number
                })
            )
        })
    ).isRequired,
    AddToCartEndpoint: PropTypes.string.isRequired,
    AddListToCartEndpoint: PropTypes.string.isRequired,
    UpdateProductEndpoint: PropTypes.string.isRequired,
    AddItemEndpoint: PropTypes.string.isRequired,
    RemoveItemEndpoint: PropTypes.string.isRequired,
    CreateListEndpoint: PropTypes.string.isRequired,
    RenameListEndpoint: PropTypes.string.isRequired,
    DeleteListEndpoint: PropTypes.string.isRequired
};

export default WishlistsApp;