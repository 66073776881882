import React from 'react';
import PropTypes from 'prop-types';
import Facet from './Facet';

const Facets = ({
    attributes,
    onAttributeChange,
    onClearAttributes,
    expandedFacets,
    onToggleFacetExpanded,
    updateOnToggle,
    showMoreFacets,
    onToggleShowMoreFacet
}) => {
    const showClearAttributes = attributes.filter(
        attribute => attribute.Values.filter(
            value => value.Checked).length > 0).length > 0;


    return (
        <div className="facets">
            <div className="row">
                <div className="col-12">
                    <div className="filter-products-row">
                        <span className="filter-products">
                            Filter Products
                        </span>
                        {showClearAttributes &&
                            <a
                                onClick={onClearAttributes}
                                className="pull-right clear-all-filters"
                                href="#"
                            >
                                Clear all filters
                            </a>
                        }
                    </div>
                </div>
            </div>
            {
                attributes.map((attribute, i) => {
                    const expanded = expandedFacets.indexOf(attribute.Name) > -1;
                    const showMore = showMoreFacets.indexOf(attribute.Name) > -1;
                    return (
                        <div className="mb-3">
                            <Facet
                                key={i}
                                name={attribute.Name}
                                displayName={attribute.DisplayName}
                                values={attribute.Values}
                                onAttributeChange={onAttributeChange}
                                expanded={expanded}
                                onToggleFacetExpanded={onToggleFacetExpanded}
                                updateOnToggle={updateOnToggle}
                                showMore={showMore}
                                onToggleShowMore={onToggleShowMoreFacet}
                            />
                        </div>
                    );
                })
            }
        </div>
    );
};

Facets.propTypes = {
    attributes: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        DisplayName: PropTypes.string,
        Values: PropTypes.arrayOf(PropTypes.shape({
            Name: PropTypes.string,
            Checked: PropTypes.bool,
            Count: PropTypes.number
        }))
    })),
    onAttributeChange: PropTypes.func,
    onClearAttributes: PropTypes.func,
    expandedFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleFacetExpanded: PropTypes.func,
    updateOnToggle: PropTypes.bool,
    showMoreFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleShowMoreFacet: PropTypes.func,
};

export default Facets;