export function buildSelectedAttributesFromModel(attributes) {
    const selectedAttributes = [];

    attributes.forEach(attribute => {
        if (attribute.Values) {
            const attributeValues = attribute.Values.filter(
                value => value.Checked && value.Checked === true);

            attributeValues.forEach(attributeValue => {
                selectedAttributes.push({
                    name: attribute.DisplayName,
                    value: attributeValue.Name
                });
            });
        }
    });

    return selectedAttributes;
}

export function buildFacetNamesFromModel(attributes) {
    if (!attributes) {
        return [];
    }

    return attributes.filter(attribute => attribute.Name)
        .map(attribute => attribute.Name);
}

export function buildStateFromModel({
    SearchString,
    Products,
    Recommendations,
    View,
    FindClickTracking,
    Suggestions,
    Articles,
    PromotionalArea
}) {
    const state = {
        searchString: SearchString ? SearchString : SearchString,
        selectedAttributes: Products && Products.ProductFilters && Products.ProductFilters.Attributes ? buildSelectedAttributesFromModel(Products.ProductFilters.Attributes) : [],
        attributes: Products && Products.ProductFilters && Products.ProductFilters.Attributes ? Products.ProductFilters.Attributes : [],
        products: Products && Products.ProductItems ? Products.ProductItems : [],
        recommendedProducts: Recommendations ? getRecommendedProducts(Recommendations) : [],
        productPaginationViewModel: Products && Products.PaginationViewModel ? Products.PaginationViewModel : null,
        articlePaginationViewModel: Articles && Articles.PaginationViewModel ? Articles.PaginationViewModel : null,
        isLoading: false,
        view: View,
        articles: Articles && Articles.ArticleItemViewModels ? Articles.ArticleItemViewModels : [],
        findClickTrackingViewModel: FindClickTracking,
        suggestions: Suggestions,
        showMobileFacets: false,
        expandedFacets: Products && Products.ProductFilters && Products.ProductFilters.Attributes ? buildFacetNamesFromModel(Products.ProductFilters.Attributes) : [],
        showMoreFacets: [],
        promotionalArea: PromotionalArea
    };

    return state;
}

function getRecommendedProducts(recommendations) {
    return recommendations.slice(0, 3).map(({ RecommendationId, TileViewModel }) => {
        return {
            RecommendationId,
            Name: TileViewModel.Name,
            Url: TileViewModel.Url,
            Image: TileViewModel.Image,
            Description: TileViewModel.Description
        };
    });
}