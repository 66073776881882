import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex'

export class AddressActionCreatorsShape { };

AddressActionCreatorsShape.propTypes = PropTypes.shape({
  add: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired
});

export class AddressShape { }

AddressShape.propTypes = PropTypes.shape({
  AddressLine1: PropTypes.string.isRequired,
  AddressLine2: PropTypes.string,
  AddressType: PropTypes.number.isRequired,
  City: PropTypes.string.isRequired,
  CountryCode: PropTypes.string.isRequired,
  CountryName: PropTypes.string.isRequired,
  Id: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  Phone: PropTypes.string,
  PostalCode : PropTypes.string.isRequired,
  State : PropTypes.string.isRequired
})

export const countriesStatesMap = (propValue, key, componentName, location, propFullName) => {
  if (!Array.isArray(propValue[key])) {
    return new Error(
      `Invalid prop \`${propFullName}\` supplied to \`${componentName}\`. Each key is a country code, with its value an array of states.`
    );
  }
  let isValidArrayElement = s => typeof s === 'string';
  if (!propValue[key].every(isValidArrayElement)) {
    return new Error(
      `Invalid prop \`${propFullName}\` supplied to ${componentName}\`. Element [${findIndex(propValue[key],
        elt => !isValidArrayElement(elt))}`);
  }
  return undefined;
};

