import React from 'react';
import ProductSearchResultsContainer from './ProductSearchResultsContainer';
import ProductSearchResultsNonFound from './ProductSearchResultsNonFound';
import PropTypes from 'prop-types';

const ProductSearchResults = ({
    attributes, 
    onAttributeChange, 
    onClearAttributes, 
    paginationViewModel,
    onCurrentItemsPerPageChange, 
    onCurrentSortByChange, 
    onCurrentPageIndexChange, 
    products,
    recommendedProducts,
    onResultClick,
    onShowMobileFacetsChange,
    expandedFacets,
    onToggleFacetExpanded,
    categoryName,
    categoryImageUrl,
    categoryImageAltText,
    categoryDescription,
    showMoreFacets,
    onToggleShowMoreFacet,
    promotionalArea
}) => {
    return (
        <div>
            {products.length > 0 ? 
                <ProductSearchResultsContainer 
                    attributes={attributes}
                    onAttributeChange={onAttributeChange} 
                    onClearAttributes={onClearAttributes} 
                    paginationViewModel={paginationViewModel}
                    onCurrentItemsPerPageChange={onCurrentItemsPerPageChange} 
                    onCurrentSortByChange={onCurrentSortByChange} 
                    onCurrentPageIndexChange={onCurrentPageIndexChange} 
                    products={products}
                    recommendedProducts={recommendedProducts}
                    onResultClick={onResultClick}
                    onShowMobileFacetsChange={onShowMobileFacetsChange}
                    expandedFacets={expandedFacets}
                    onToggleFacetExpanded={onToggleFacetExpanded}
                    categoryName={categoryName}
                    categoryImageUrl={categoryImageUrl}
                    categoryImageAltText={categoryImageAltText}
                    categoryDescription={categoryDescription}
                    showMoreFacets={showMoreFacets}
                    onToggleShowMoreFacet={onToggleShowMoreFacet}
                    promotionalArea={promotionalArea}
                />
            : <ProductSearchResultsNonFound />
            }
        </div>
    );
};

ProductSearchResults.propType = {
    attributes: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        DisplayName: PropTypes.string,
        Values: PropTypes.arrayOf(PropTypes.shape({
            Name: PropTypes.string,
            Checked: PropTypes.bool,
            Count: PropTypes.number
        }))
    })),
    onAttributeChange: PropTypes.func, 
    onClearAttributes: PropTypes.func, 
    paginationViewModel: PropTypes.shape({
        ResultsTotal: PropTypes.number,
        CurrentItemsPerPage: PropTypes.number,
        CurrentPageIndex: PropTypes.number,
        CurrentSortBy: PropTypes.string,
        CurrentItemsOnPage: PropTypes.number
    }),
    onCurrentItemsPerPageChange: PropTypes.func, 
    onCurrentSortByChange: PropTypes.func, 
    onCurrentPageIndexChange: PropTypes.func, 
    products: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string,
        Image: PropTypes.shape({
            Url: PropTypes.string,
            Alt: PropTypes.string
        }),
        Description: PropTypes.string
    })),
    recommendedProducts: PropTypes.arrayOf(PropTypes.shape({
        RecommendationId: PropTypes.number,
        Name: PropTypes.string,
        Url: PropTypes.string,
        Image: PropTypes.shape({
            Url: PropTypes.string,
            Alt: PropTypes.string
        }),
        Description: PropTypes.string
    })),
    expandedFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleFacetExpanded: PropTypes.func,
    showMoreFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleShowMoreFacet: PropTypes.func,
    promotionalArea: PropTypes.string
};

export default ProductSearchResults;