// eslint-disable-next-line
import React from 'react'
import find from 'lodash/find'
import { connect } from 'react-redux'
import { translateResourceString } from '../../../util/translationUtility';

import {
    addShippingAddress,
    selectShippingAddress,
    deleteAddress,
    updateAddress,
    selectShippingAndBillingAddress,
    UseBillingForShippingToggle
} from './addressActionCreators'

import CheckoutAddressComponent from './CheckoutAddressComponent'

const mapShippingAddressStateToProps = (state) => {
    const e = state.entities;
    const c = state.constants;
    const useShippingForBilling = state.ui.useShippingForBilling;
    return {
        addresses: e.ShippingAddresses.map(id => find(e.Addresses, address => address.Id === id)),
        allowAdding: c.allowAddingShippingAddresses,
        selectedAddressId: e.SelectedShippingAddressId,
        addressType: c.ShippingAddressType,
        addressActionCreators: {
            add: addShippingAddress,
            remove: deleteAddress,
            select: useShippingForBilling ? selectShippingAndBillingAddress : selectShippingAddress,
            update: updateAddress
        },
        selectAddressMessage: useShippingForBilling ? translateResourceString("/Checkout/Address/CheckoutShippingAddress/SelectAddressMessage/BillAndShipTo")
                                                    : translateResourceString("/Checkout/Address/CheckoutShippingAddress/ShipTo"),
        useForAnotherMessage: translateResourceString("/Checkout/Address/CheckoutShippingAddress/UseForAnotherMessage"),
        useForAnotherSelected: useShippingForBilling,
        sectionLabel: translateResourceString("/Checkout/Address/CheckoutShippingAddress/SectionLabel")
    }
}

const mapShippingAddressDispatchToProps = dispatch => {
    return {
        onEndEdit: (Id) => dispatch(selectShippingAddress(Id)),
        onUseForAnotherClicked: () => dispatch(UseBillingForShippingToggle())
    }
}

const CheckoutShippingAddressContainer = connect(
    mapShippingAddressStateToProps,
    mapShippingAddressDispatchToProps
)(CheckoutAddressComponent);

export default CheckoutShippingAddressContainer;