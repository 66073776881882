import React from 'react';
import DropdownOption from './DropdownOption';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const SORT_OPTIONS = [
    new DropdownOption('Name (A-Z)', 'NameAZ', 'Sort By Name (A-Z)'),
    new DropdownOption('Name (Z-A)', 'NameZA', 'Sort By Name (Z-A)'),
    new DropdownOption('Recently Added', 'RecentlyAdded', 'Sort By Recently Added')
];

class SortByDropdown extends React.Component {
    constructor(props) {
        super(props)

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        const { CurrentSortBy, onCurrentSortByChange } = this.props;
        
        // Need to go through array and find when it matches
        const found = SORT_OPTIONS.find(option => {
            return option.value === CurrentSortBy;
        });

        const selectedSort = found ? found : SORT_OPTIONS[0];
        const title = <span><span className="hidden-xxs">Sort By </span>{selectedSort.displayName} <i className="fa fa-angle-down" /></span>;
        return (
            <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
                id={`sort-by-dropdown`}
            >
                <DropdownToggle>
                    {title}
                </DropdownToggle>
                <DropdownMenu>
                    {SORT_OPTIONS.map((option, i) => {
                        return (
                            <DropdownItem
                                key={i}
                                active={selectedSort === option}
                                onClick={() => onCurrentSortByChange(option.value)}
                            >
                                {option.displayName}
                            </DropdownItem>
                        );
                    })

                    }
                </DropdownMenu>
            </Dropdown>
        );
    }
};

SortByDropdown.propTypes = {
    CurrentSortBy: PropTypes.string,
    onCurrentSortByChange: PropTypes.func
};

export default SortByDropdown;