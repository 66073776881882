// eslint-disable-next-line
import React from 'react'
import PropTypes from 'prop-types'

import { RadioInput } from '../../components'

import AddressDisplay from './AddressDisplay'

import { AddressShape } from './propTypes'

const r = {
  paddingTop: 10,
  paddingLeft: 15,
  paddingRight: 15,
  paddingBottom: 0
}

let ActiveAddressInAddressSelectorList = (
  {
    address,
    isSelected,
    onSelected,
    onRadioSelected,
    onBeginEdit,
    onDelete,
    selectAddressMessage
  }) => (
    <div
      className='row'
      style={{backgroundColor: '#eee', paddingTop: '1rem', paddingBottom:  '1rem' }}
    >
    <div className='col-12 d-flex'>
        <RadioInput selected={isSelected} onClick={() => onRadioSelected(address.Id)} />
        <AddressDisplay address={address} compact={false} />
      </div>
      <div style={r}>
        <button
          className='btn btn-link p-0'
          onClick={(e) => e.preventDefault() || onSelected(address.Id)}
        >
          {selectAddressMessage}
        </button>
      
        <button className='btn btn-link'
          onClick={(e) => e.preventDefault() || onBeginEdit(address.Id)}
        >
          Edit
        </button>

        <button className='btn btn-link'
          onClick={(e) => e.preventDefault() || onDelete(address.Id)}
        >
          Delete
        </button>
    </div>
  </div>
)

ActiveAddressInAddressSelectorList.propTypes = {
  address: PropTypes.shape(AddressShape).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelected: PropTypes.func.isRequired,
  onRadioSelected: PropTypes.func.isRequired,
  onBeginEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectAddressMessage: PropTypes.string.isRequired
}

export default ActiveAddressInAddressSelectorList