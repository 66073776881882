import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

const EditListModalLink = ({
    ClassName = "",
    Color = "",
    Size = "",
    ToggleEditListModal,
    Name
}) => {

    return (

        <Button className={ClassName} color={Color} size={Size} title="Edit" onClick={() => {
                ToggleEditListModal({
                    EditListModalIsOpen: true,
                    ListName: Name
                })
            }}>
            <i className="fas fa-pen-square"></i>
        </Button>
    );
};

EditListModalLink.propType = {
    ClassName: PropTypes.string,
    Color: PropTypes.string,
    Size: PropTypes.string,
    ToggleEditListModal: PropTypes.func.isRequired,
    Name: PropTypes.string.isRequired
};

export default EditListModalLink;