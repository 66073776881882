import { connect } from 'react-redux'

import CheckoutCoupon from './CheckoutCoupon'
import { addCouponAsync, removeCouponAsync, updateCouponsAsync } from './couponActionCreators'

const mapCheckoutCouponStateToProps = (state) => {
    return {
        Coupons: state.entities.Coupons,
        CartItems: state.entities.CartItems,
        SelectedShippingOptionId: state.entities.SelectedShippingOptionId
    };
}

const mapCheckoutCouponDispatchToProps = (dispatch) => {
    return {
        onAddCoupon: (code) => (dispatch(addCouponAsync({ code }))),
        onRemoveCoupon: (code) => (dispatch(removeCouponAsync({ code }))),
        updateCoupons: () => dispatch(updateCouponsAsync())
    }
}

const CheckoutCouponContainer = connect(mapCheckoutCouponStateToProps, mapCheckoutCouponDispatchToProps)(CheckoutCoupon);

export default CheckoutCouponContainer