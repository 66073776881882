import React from 'react';
import TypeaheadEntry from './TypeaheadEntry';
import { buildSearchTermUrl } from './urlBuilder.js';
import PropTypes from 'prop-types';

const TypeaheadResults = ({
    searchTerms,
    categories,
    products,
    articles,
    currentSearchTerm,
    searchSubmitLink,
    selected,
    translationUtility
}) => {
    return (
        <div id="typeahead-results">
            {searchTerms.length > 0 &&
                <div
                    id="typeahead-results-search-terms"
                    className="typeahead-results-list"
                >
                    <h5>{translationUtility.translateResourceString("/Typeahead/Index/SearchTermResultsHeading")}</h5>
                    {searchTerms.map((searchTerm, key) => {
                        const href = buildSearchTermUrl({ searchSubmitLink, searchTerm });
                        return (
                            <TypeaheadEntry key={key} href={href} name={searchTerm} selected={selected && searchTerm === selected} />
                        )
                    })}
                </div>
            }
            {/* Not currently displaying categories */}
            {/* {categories.length > 0 &&
                <div
                    id="typeahead-results-categories"
                    className="typeahead-results-list"
                >
                    <h5>{translationUtility.translateResourceString("/Typeahead/Index/SearchTermResultseading")}</h5>
                    {categories.map((category, key) => {
                        return (
                            <TypeaheadEntry key={key} href={category.Url} name={category.Name} selected={selected && category === selected} />
                        )
                    })}
                </div>
            } */}
            {products.length > 0 &&
                <div
                    id="typeahead-results-products"
                    className="typeahead-results-list"
                >
                    {products.map((product, key) => {
                        return (
                            <TypeaheadEntry key={key} href={product.Url} name={product.Name} selected={selected && product === selected} imageUrl={product.ImageUrl} />
                        )
                    })}
                </div>
            }
            {/* Not currently displaying articles */}
            {/* {articles.length > 0 &&
                <div
                    id="typeahead-results-articles"
                    className="typeahead-results-list"
                >
                    <h5>{translationUtility.translateResourceString("/Typeahead/Index/ArticleResultseading")}</h5>
                    {articles.map((article, key) => {
                        return (
                            <TypeaheadEntry key={key} href={article.Url} name={article.Name} selected={selected && article === selected} />
                        )
                    })}
                </div>
            } */}
            {currentSearchTerm &&
                <div className="typeahead-results-list">
                    <a
                        href={buildSearchTermUrl({ searchSubmitLink, searchTerm: currentSearchTerm })}
                        id="typeahead-results-see-all-results"
                    >
                        {translationUtility.translateResourceString("/Typeahead/Index/SeeAllResultsLink")} <i className="fa fa-long-arrow-right" />
                    </a>
                </div>
            }
        </div>
    )
}

TypeaheadResults.propTypes = {
    searchTerms: PropTypes.arrayOf(PropTypes.string),
    categories: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string
    })),
    products: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string,
        ImageUrl: PropTypes.string
    })),
    articles: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string
    })),
    currentSearchTerm: PropTypes.string,
    searchSubmitLink: PropTypes.string,
    selected: PropTypes.any
};

export default TypeaheadResults;