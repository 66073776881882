import React from 'react';
import Facets from '../Facets/Facets';
import PaginationContainer from './PaginationContainer';
import ProductTileContainer from './ProductTileContainer';
import PropTypes from 'prop-types';
import MoreButton from '../../components/MoreButton';
import MobileSelectedFacets from '../Facets/MobileSelectedFacets';

const ProductSearchResultsContainer = ({ attributes,
    onAttributeChange,
    onClearAttributes,
    paginationViewModel,
    onCurrentItemsPerPageChange,
    onCurrentSortByChange,
    products,
    recommendedProducts,
    onResultClick,
    onShowMobileFacetsChange,
    expandedFacets,
    onToggleFacetExpanded,
    categoryName,
    categoryImageUrl,
    categoryImageAltText,
    categoryDescription,
    showMoreFacets,
    onToggleShowMoreFacet,
    promotionalArea
}) => {
    //const showClearAttributes = attributes.filter(
    //    attribute => attribute.Values.filter(
    //        value => value.Checked).length > 0).length > 0;
    const perMoreAmount = 12;
    const nextRequest = Math.min(paginationViewModel.CurrentItemsOnPage + perMoreAmount, paginationViewModel.ResultsTotal);
    const showMore = paginationViewModel.ResultsTotal > paginationViewModel.CurrentItemsOnPage;
    var recommendationsHeading = "Trending in Category";
    const categoryDescriptionHtml = {
        __html: categoryDescription
    };
    const promotionalAreaHtml = {
        __html: promotionalArea
    };
    return (
        <div>
            <div className="row">
                <div className="facets-wrapper col-md-3">
                    <Facets
                        attributes={attributes}
                        onAttributeChange={onAttributeChange}
                        onClearAttributes={onClearAttributes}
                        expandedFacets={expandedFacets}
                        onToggleFacetExpanded={onToggleFacetExpanded}
                        showMoreFacets={showMoreFacets}
                        onToggleShowMoreFacet={onToggleShowMoreFacet}
                    />
                    {promotionalArea && <div className="promotional-area" dangerouslySetInnerHTML={promotionalAreaHtml}></div>}
                </div>
                <div className="product-list-wrapper col-md-9 col-sm-12">
                    {categoryName && categoryName.length > 0 &&
                        <div className="row">
                            <div className="col-12 banner-wrapper">
                                <img 
                                    src={categoryImageUrl} 
                                    alt={categoryImageAltText}
                                    className="img-fluid"  
                                />
                                <div className="category-details-wrapper">
                                    <h1>{categoryName}</h1>
                                    <div className="d-m-block" dangerouslySetInnerHTML={categoryDescriptionHtml}></div>
                                </div>
                            </div>
                        </div>
                    }
                    {recommendedProducts && recommendedProducts.length > 0 &&
                        <div className="row">
                            <div className="product-list col-12 js-recommendations-list">
                                <div className="product-section-header">
                                    <h4 className="heading-orange">{recommendationsHeading}</h4>
                                </div>
                                <ProductTileContainer products={recommendedProducts} onResultClick={onResultClick} />
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-12">
                            <div className="category-list-top-pagination-wrapper">
                                <PaginationContainer
                                    paginationViewModel={paginationViewModel}
                                    onCurrentSortByChange={onCurrentSortByChange}
                                    onShowMobileFacetsChange={onShowMobileFacetsChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row d-md-none">
                        <div className="col-12">
                            <MobileSelectedFacets
                                attributes={attributes}
                                onAttributeChange={onAttributeChange}
                                onClearAttributes={onClearAttributes}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="product-list col-12 js-product-list">
                            <ProductTileContainer products={products} onResultClick={onResultClick} />
                        </div>
                    </div>
                    {showMore &&
                        <div className="row">
                            <div className="col-12 text-center">
                                <MoreButton
                                    onClick={() => onCurrentItemsPerPageChange(nextRequest)}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

ProductSearchResultsContainer.propType = {
    attributes: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        DisplayName: PropTypes.string,
        Values: PropTypes.arrayOf(PropTypes.shape({
            Name: PropTypes.string,
            Checked: PropTypes.bool,
            Count: PropTypes.number
        }))
    })),
    onAttributeChange: PropTypes.func,
    onClearAttributes: PropTypes.func,
    paginationViewModel: PropTypes.shape({
        ResultsTotal: PropTypes.number,
        CurrentSortBy: PropTypes.string,
        CurrentItemsOnPage: PropTypes.number
    }),
    onCurrentItemsPerPageChange: PropTypes.func,
    onCurrentSortByChange: PropTypes.func,
    products: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Url: PropTypes.string,
        Image: PropTypes.shape({
            Url: PropTypes.string,
            Alt: PropTypes.string
        }),
        Description: PropTypes.string
    })),
    recommendedProducts: PropTypes.arrayOf(PropTypes.shape({
        RecommendationId: PropTypes.number,
        Name: PropTypes.string,
        Url: PropTypes.string,
        Image: PropTypes.shape({
            Url: PropTypes.string,
            Alt: PropTypes.string
        }),
        Description: PropTypes.string
    })),
    onShowMobileFacetsChange: PropTypes.func.isRequired,
    expandedFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleFacetExpanded: PropTypes.func,
    showMoreFacets: PropTypes.arrayOf(PropTypes.string),
    onToggleShowMoreFacet: PropTypes.func,
    promotionalArea: PropTypes.string
};

export default ProductSearchResultsContainer;
