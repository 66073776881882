import React from 'react';
import { Card, CardHeader, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
const downArrow = <i className="fa fa-angle-down" />;
const upArrow = <i className="fa fa-angle-up" />;
const showMoreThreshold = 10;

class Facet extends React.Component {
    componentWillMount() {
        this.setState({ expanded: true });
    }

    render() {
        const {
            name,
            displayName,
            values,
            onAttributeChange,
            expanded,
            onToggleFacetExpanded,
            updateOnToggle,
            showMore,
            onToggleShowMore,
        } = this.props;
        const arrow = expanded ? upArrow : downArrow;
        const sizeToShow = showMore
            ? values.length
            : Math.min(showMoreThreshold, values.length);
        const showShowMore = !showMore && values.length > showMoreThreshold;
        return (
            <Card>
                <CardHeader>
                    <div onClick={() => onToggleFacetExpanded(name)}>
                        <span className="facet-display-name">
                            {displayName}
                        </span>
                        <span className="pull-right">
                            {arrow}
                        </span>
                    </div>
                </CardHeader>
                <Collapse isOpen={expanded}>
                    <div className="list-group">
                        {
                            values.slice(0, sizeToShow).map((value, k) => {
                                return (
                                    <button 
                                        type="button" 
                                        key={k} 
                                        className={"list-group-item d-flex justify-content-between align-items-center list-group-item-action" + (value.Checked ? " active" : "")}
                                        onClick={() => {
                                                    if (updateOnToggle) {
                                                        value.Checked = !value.Checked;
                                                    }
                                                    onAttributeChange(name, value.Name);
                                                }}
                                    >
                                        {value.Name}
                                        <span className="badge badge-primary badge-pill">{value.Count}</span>
                                    </button>
                                )
                            })
                        }
                        {showShowMore && <a className="show-more" style={{ display: 'block', marginTop: '10px', marginBottom: '10px' }} onClick={() => onToggleShowMore(name)}>Show More...</a>}
                    </div>
                </Collapse>
            </Card>
        );
    }
}

Facet.propTypes = {
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
        Name: PropTypes.string,
        Checked: PropTypes.bool,
        Count: PropTypes.number
    })).isRequired,
    onAttributeChange: PropTypes.func,
    expanded: PropTypes.bool,
    onToggleFacetExpanded: PropTypes.func,
    updateOnToggle: PropTypes.bool,
    showMore: PropTypes.bool,
    onToggleShowMore: PropTypes.func,
};

export default Facet;