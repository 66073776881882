import {
  SELECT_PAYMENT_OPTION,
  BRAINTREEE_PAYMENT_DATA_UPDATE,
  PURCHASE_ORDER_PAYMENT_DATA_UPDATE
} from '../../GlobalStateContainer'

/* PAYMENT METHOD */

export function selectPaymentOption(paymentMethodId, displayName) {
  return { type: SELECT_PAYMENT_OPTION, paymentMethodId, displayName }
}

export function updateBraintreePaymentData(payload) {
  return { type: BRAINTREEE_PAYMENT_DATA_UPDATE, payload }
}

export function updatePurchaseOrderNumber(number) {
  return { type: PURCHASE_ORDER_PAYMENT_DATA_UPDATE, number }
}
