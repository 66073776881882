import React from 'react';

const ProductSearchResultsNonFound = () =>
<div>
    No Products Found
</div>;

ProductSearchResultsNonFound.propType = {};

export default ProductSearchResultsNonFound;

