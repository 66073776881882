import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle } from 'reactstrap';

class Article extends React.Component {
    render() {
        const { Link, Title, Description, onClick } = this.props;
        return (     
            <Card>
                <CardBody>
                    <CardTitle>
                        <a href={Link} onClick={onClick}>
                            {Title}
                        </a>
                    </CardTitle>
                    {Description}
                </CardBody>
            </Card>
        );
    }
}

Article.propType = {
    Link: PropTypes.string,
    Title: PropTypes.string,
    Description: PropTypes.string,
    onClick: PropTypes.func
};

export default Article;