import React from 'react';
import PropTypes from 'prop-types';
import ArticleSearchResultsContainer from './ArticleSearchResultsContainer';
import ArticleSearchResultsNonFound from './ArticleSearchResultsNonFound';

const ArticleSearchResults = ({ articles,
    paginationViewModel,
    onCurrentItemsPerPageChange,
    onCurrentPageIndexChange,
    onResultClick }
) => {
    return (
        <div>
            {articles.length ?
                <ArticleSearchResultsContainer 
                    articles={articles} 
                    paginationViewModel={paginationViewModel}
                    onCurrentItemsPerPageChange={onCurrentItemsPerPageChange}
                    onCurrentPageIndexChange={onCurrentPageIndexChange}
                    onResultClick={onResultClick}
                />
            :   <ArticleSearchResultsNonFound />
        }
        </div>
    );
};

ArticleSearchResults.propType = {
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            Title: PropTypes.string,
            Link: PropTypes.string,
            Description: PropTypes.string
        })
    ),
    paginationViewModel: PropTypes.shape({
        CurrentPageIndex: PropTypes.number,
        CurrentItemsPerPage: PropTypes.number,
        ResultsTotal: PropTypes.number,
        CurrentItemsOnPage: PropTypes.number
    }),
    onCurrentItemsPerPageChange: PropTypes.func,
    onCurrentPageIndexChange: PropTypes.func,
    onResultClick: PropTypes.func
};

export default ArticleSearchResults;