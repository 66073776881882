import { combineReducers } from 'redux'

import {
  Addresses,
  BillingAddresses,
  ShippingAddresses,
  SelectedBillingAddressId,
  SelectedShippingAddressId,
  useShippingForBilling
} from '../Features/Address/addressReducers'

import {
  PhoneNumber,
  PhoneText,
  completeCheckoutButtonText
 } from './uiReducers'

import {
  CartItems 
} from '../Features/Cart/cartReducers'

import {
  PaymentOptions,
  SelectedPaymentOptionId,
  PaymentRecord
} from '../Features/PaymentMethod/paymentMethodReducers'

import {
  Summary,
  isRefreshingSummary
} from '../Features/Summary/summaryReducers'

import { isPlaceOrderPending } from '../Features/PlaceOrder/placeOrderReducers'

import {
  ShippingOptions,
  SelectedShippingOptionId,
  isRefreshingShippingOptions
} from '../Features/ShippingMethod/shippingMethodReducers'

import {
  Coupons
} from '../Features/Coupon/couponReducers'


import { messages } from '../Features/UserMessaging/userMessagingReducers'

const entities = combineReducers({
  // Address
  Addresses,
  BillingAddresses,
  SelectedBillingAddressId,
  ShippingAddresses,
  SelectedShippingAddressId,

  // Cart
  CartItems,

  // Payment
  PaymentOptions,
  SelectedPaymentOptionId,
  PaymentRecord,

  // Shipping
  ShippingOptions,
  SelectedShippingOptionId,
  isRefreshingShippingOptions,

  //Summary
  Summary,
  isRefreshingSummary,

  // Coupon
  Coupons
});

const ui = combineReducers({
  // Address
  useShippingForBilling,

  // UserMessaging
  messages,

  // PlaceOrder
  isPlaceOrderPending,

  PhoneNumber,
  PhoneText,
  completeCheckoutButtonText,
});

function constants(state = {}, action) {
  return state; // they're constants. duh.
}

export const checkoutApp = combineReducers({
  ui,
  entities,
  constants
})
