import React from 'react'
import PropTypes from 'prop-types'
import CopyModalTab from './CopyModalTab'
import NewListInput from '../../Components/NewListInput'
import ProductItem from '../../Components/ProductItem'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import axios from 'axios';

const CopyModal = ({
    Lists = [],
    UpdateLists,
    CopyModalIsOpen,
    ToggleCopyModal,
    ProductCommand,
    ToggleNotification,
    CopyItemNotificationText,
    MoveItemNotificationText,
    AddItemEndpoint,
    RemoveItemEndpoint,
    CreateListEndpoint
}) => {

    let product = null
    const originalListIndex = Lists.findIndex(x => x.Name === ProductCommand.ListId)
    if (originalListIndex !== -1) product = Lists[originalListIndex].Items.find(x => x.Id === ProductCommand.ProductId)

    const copyProduct = (targetListName) => {
        axios.post(AddItemEndpoint, {
            listName: targetListName,
            skus: { [product.SKU]: product.Quantity }
        })
            .then(function (response) {
                switch (response.data) {
                    case 'OK':
                        const index = Lists.findIndex(x => x.Name === targetListName)
                        let newItems = []

                        if (Lists[index].Items.some(x => x.SKU === product.SKU)) {
                            let productIndex = Lists[index].Items.findIndex(x => x.SKU === product.SKU)
                            let existingProduct = Lists[index].Items[productIndex]
                            existingProduct.Quantity += product.Quantity
                            newItems = Lists[index].Items;
                        } else {
                            // Create a copy of the product so changes don't affect each other.
                            newItems = [JSON.parse(JSON.stringify(product)), ...Lists[index].Items];
                        }

                        const newList = [
                            ...Lists.slice(0, index),
                            {
                                Name: targetListName,
                                Items: newItems
                            },
                            ...Lists.slice(index + 1, Lists.length)
                        ]

                        closeModal(newList, ProductCommand.RemoveAfter, targetListName)
                        break
                    case 'BadRequest':
                        alert('Invalid parameters')
                        break
                    case 'InternalServerError':
                        alert('Something went wrong')
                        break
                    default:
                        console.error('Unhandled Response.')
                        break
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const addProduct = (targetListName) => {
        axios.post(AddItemEndpoint, {
            listName: targetListName,
            skus: { [product.SKU]: product.Quantity }
        })
            .then(function (response) {
                switch (response.data) {
                    case 'OK':
                        const newList = [{
                            Name: targetListName,
                            Items: [product]
                        }, ...Lists]
                        closeModal(newList, ProductCommand.RemoveAfter, targetListName)
                        break
                    case 'BadRequest':
                        alert('Invalid parameters')
                        break
                    case 'InternalServerError':
                        alert('Something went wrong')
                        break
                    default:
                        console.error('Unhandled Response.')
                        break
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const closeModal = (newList, removeAfter, targetListName) => {
        if (removeAfter) {
            removeProduct(newList, ProductCommand.ListId)
        } else {
            UpdateLists(newList)
            ToggleCopyModal(false)
        }

        ToggleNotification({ 
            IsOpen: true, 
            Message: removeAfter ? MoveItemNotificationText : CopyItemNotificationText, 
            TargetListName: targetListName 
        })
    }

    const removeProduct = (newList, listName) => {
        axios.post(RemoveItemEndpoint, {
            listName: listName,
            sku: product.SKU
        })
            .then(function (response) {
                switch (response.data) {
                    case 'OK':
                        const listIndex = newList.findIndex(x => x.Name === ProductCommand.ListId)
                        const list = [
                            ...newList.slice(0, listIndex),
                            {
                                Name: ProductCommand.ListId,
                                Items: newList[listIndex].Items.filter(x => x.SKU !== product.SKU)
                            },
                            ...newList.slice(listIndex + 1, newList.length)
                        ]
                        UpdateLists(list)
                        ToggleCopyModal(false)
                        break
                    case 'BadRequest':
                        alert('Invalid parameters')
                        break
                    case 'InternalServerError':
                        alert('Something went wrong')
                        break
                    default:
                        console.error('Unhandled Response.')
                        break
                }
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    return (
        <Modal
            isOpen={CopyModalIsOpen}
            toggle={() => ToggleCopyModal(false)}
            centered={true}
            className="modal-lg"
            contentClassName="list-modal"
            role="document">
            <ModalHeader toggle={() => ToggleCopyModal(false)} wrapTag="div" tag="span" className="headline headline-sm">Select a List</ModalHeader>
            <ModalBody className="list-sidebar pb-0">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-lg-4 d-none d-lg-block list-modal-product">
                            <ProductItem {...product} />
                        </div>

                        <ul className="col-12 col-lg-8 p-0">
                            {
                                Lists.map((list, i) =>
                                    <CopyModalTab key={i} {...list} CopyProduct={copyProduct} />
                                )
                            }
                            <div className="p-3 mt-5 modal-create-list">
                                <h4>Create a new List</h4>
                                <NewListInput
                                    Lists={Lists}
                                    UpdateLists={UpdateLists}
                                    CreateListEndpoint={CreateListEndpoint}
                                    AddProduct={addProduct} />
                            </div>
                        </ul>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

CopyModal.propType = {
    Lists: PropTypes.array.isRequired,
    UpdateLists: PropTypes.func.isRequired,
    CopyModalIsOpen: PropTypes.bool.isRequired,
    ToggleCopyModal: PropTypes.func.isRequired,
    ProductCommand: PropTypes.shape({
        ListId: PropTypes.string.isRequired,
        ProductId: PropTypes.number.isRequired,
        RemoveAfter: PropTypes.bool.isRequired
    }).isRequired,
    ToggleNotification: PropTypes.func.isRequired,
    CopyItemNotificationText: PropTypes.string.isRequired,
    MoveItemNotificationText: PropTypes.string.isRequired,
    AddItemEndpoint: PropTypes.string.isRequired,
    RemoveItemEndpoint: PropTypes.string.isRequired,
    CreateListEndpoint: PropTypes.string.isRequired
};

export default CopyModal;